import React from 'react'
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

// Icons
import iconName from '../../assets/input-icon-name.svg';
import email from '../../assets/input-icon-email.svg';
import phone from '../../assets/input-icon-phone.svg';
import position from '../../assets/input-icon-position.svg';

const NameInputLabelStyled = styled.label`
    display: flex;
    position: relative;

    height: fit-content;
    width: 100%;

    &::after {
        content: "*";
        position: absolute;
        color: red;

        right: 13px;
        top: 20px;

    }

    @media (min-width:1280px) and (min-height: 750px) {
        max-width: 350px;

        &::after {
            top: 34px;

        }
    }

    input {
        display: block;
        width: 100%;

        background: url(${iconName}) no-repeat;
        background-position: 0px;

        border: 3px solid rgba(129, 145, 159, 0.3);

        padding: 10px;
        padding-left: 67px;

        color: var(--main-color-grey);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-top: 8px;
        margin-bottom: 8px;

        transition: .2s ease-in-out;

        &::placeholder {
            color: var(--main-color-darkGrey)
        }

        &:focus {
            outline: none;
            border-color: rgba(129, 145, 159, 0.8);
        }

        @media (min-width:1280px) and (min-height: 750px) {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            margin-top: 18px;
            margin-bottom: 18px;

            &::placeholder {
                font-weight: 500;
            }
        }
    }

`

export const NameInput = ({
    value,
    onChange,
    isValid,
    disabled
}) => {
    const { t, i18n } = useTranslation('formWidgets');

    return (
        <NameInputLabelStyled
            isValid={isValid}
            value={value}
        >
            <input
                type="text"
                placeholder={t(`nameInput.placeholder`)}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </NameInputLabelStyled>
    )
}


const EmailInputLabelStyled = styled.label`
    display: flex;
    position: relative;

    height: fit-content;
    width: 100%;

    &::after {
        content: "*";
        position: absolute;
        color: red;

        right: 13px;
        top: 20px;

    }

    @media (min-width:1280px) and (min-height: 750px) {
        max-width: 350px;

        &::after {
            top: 34px;

        }
    }

    input {
        display: block;
        width: 100%;

        background: url(${email}) no-repeat;
        background-position: 0px;

        border: 3px solid rgba(129, 145, 159, 0.3);

        padding: 10px;
        padding-left: 67px;

        color: var(--main-color-grey);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-top: 8px;
        margin-bottom: 8px;

        transition: .2s ease-in-out;

        &::placeholder {
            color: var(--main-color-darkGrey)
        }

        &:focus {
            outline: none;
            border-color: rgba(129, 145, 159, 0.8);
        }

        @media (min-width:1280px) and (min-height: 750px) {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            margin-top: 18px;
            margin-bottom: 18px;

            &::placeholder {
                font-weight: 500;
            }
        }
    }

`

export const EmailInput = ({
    value,
    onChange,
    isValid,
    disabled
}) => {
    const { t, i18n } = useTranslation('formWidgets');

    return (
        <EmailInputLabelStyled
            isValid={isValid}
            value={value}
        >
            <input
                type="email"
                placeholder={t(`emailInput.placeholder`)}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </EmailInputLabelStyled>
    )
}

const PhoneInputLabelStyled = styled.label`
    display: flex;
    position: relative;

    height: fit-content;
    width: 100%;

    @media (min-width:1280px) and (min-height: 750px) {
        max-width: 350px;
    }

    input {
        display: block;
        width: 100%;

        background: url(${phone}) no-repeat;
        background-position: 0px;

        border: 3px solid rgba(129, 145, 159, 0.3);

        padding: 10px;
        padding-left: 67px;

        color: var(--main-color-grey);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-top: 8px;
        margin-bottom: 8px;

        transition: .2s ease-in-out;

        &::placeholder {
            color: var(--main-color-darkGrey)
        }

        &:focus {
            outline: none;
            border-color: rgba(129, 145, 159, 0.8);
        }

        @media (min-width:1280px) and (min-height: 750px) {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            margin-top: 18px;
            margin-bottom: 18px;

            &::placeholder {
                font-weight: 500;
            }
        }
    }

`

export const PhoneInput = ({
    value,
    onChange,
    isValid,
    disabled
}) => {
    const { t, i18n } = useTranslation('formWidgets');

    return (
        <PhoneInputLabelStyled
            isValid={isValid}
            value={value}
        >
            <input
                type="tel"
                placeholder={t(`phoneInput.placeholder`)}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </PhoneInputLabelStyled>
    )
}

const PositionInputLabelStyled = styled.label`
    display: flex;
    position: relative;

    height: fit-content;
    width: 100%;

    @media (min-width:1280px) and (min-height: 750px) {
        max-width: 350px;
    }

    input {
        display: block;
        width: 100%;

        background: url(${position}) no-repeat;
        background-position: 0px;

        border: 3px solid rgba(129, 145, 159, 0.3);

        padding: 10px;
        padding-left: 67px;

        color: var(--main-color-grey);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-top: 8px;
        margin-bottom: 8px;

        transition: .2s ease-in-out;

        &::placeholder {
            color: var(--main-color-darkGrey)
        }

        &:focus {
            outline: none;
            border-color: rgba(129, 145, 159, 0.8);
        }

        @media (min-width:1280px) and (min-height: 750px) {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            margin-top: 18px;
            margin-bottom: 18px;

            &::placeholder {
                font-weight: 500;
            }
        }
    }

`

export const PositionInput = ({
    value,
    onChange,
    isValid,
    disabled
}) => {
    const { t, i18n } = useTranslation('formWidgets');

    return (
        <PositionInputLabelStyled
            isValid={isValid}
            value={value}
        >
            <input
                type="text"
                placeholder={t(`positionInput.placeholder`)}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </PositionInputLabelStyled>
    )
}
