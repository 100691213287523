import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';

import GlobalStyle from './globalStyles';

import { Provider } from 'react-redux';
import store from './app/store';

import i18n from './i18n';

import App from './App';



ReactDOM.render(
  <React.StrictMode>
    <Provider
      store={store}
    >
      <GlobalStyle />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
