import { createSlice } from '@reduxjs/toolkit';

// Default state
const defaultState = {
    name: '',
    email: '',
    phoneNumber: '',
    // Enum: 'contractor', 'roadAgency', 'municipality', 'privateCompany', 'other'
    organizationType: '',
    position: '',
    agreedToTerms: false,
    isSumbitUserInfoLoading: false,
    sumbitUserInfoError: null
}

const initialState = defaultState;

// Initialize state and define reducers
const userInfoState = createSlice({
    name: 'userInfoState',
    initialState,
    reducers: {
        _setUserInfoField(state, action) {
            const { field, value } = action.payload;
            state[field] = value;
        },
        _resetUserInfoFields(state, action) {
            state.name = ''
            state.email = '';
            state.phoneNumber = '';
            state.organizationType = '';
            state.position = '';
            state.agreedToTerms = false;
        }
    }
});

export const {
    _setUserInfoField,
    _resetUserInfoFields
} = userInfoState.actions;

export default userInfoState.reducer;