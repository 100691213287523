import React from 'react'
import styled, { css } from 'styled-components';


const DownLoadLinkStyled = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;

    border: transparent;
    outline: none;

    text-decoration: none;
    
    padding: 10px;

    background-color: var(--main-color-darkGrey);
    color: #FFFFFF;

    cursor: pointer;

    transition: .2s ease-in-out;

    ${props => (
        props.disabled
        ?
        css`
            cursor: default;
            
            background-color: var(--bg-color-2);

            span {
                color: var(--main-color-darkGrey);
                opacity: 0.2;
            }

            svg {
                opacity: 0.2;
                path {
                    fill: var(--main-color-darkGrey);
                }
            }
        `
        :
        ``
    )}

    span {
        margin-right: 18px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;

        text-transform: uppercase;
    }

    svg {
        display: block;
    }
`


export const DownLoadLink = ({
    labelText,
    href,
    disabled,
    style,
    onClick
}) => {

    return (
        <DownLoadLinkStyled
            title={labelText}
            href={href}
            disabled={disabled}
            style={{...style}}
            download
            onClick={onClick ? onClick : () => {}}
        >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M18 12H22L15 19L8 12H12V6H18V12ZM8 23V21H22V23H8Z" fill="white"/>
            </svg>
            <span>
                {labelText} 
            </span>
        </DownLoadLinkStyled>
    );
}
