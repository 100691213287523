import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const SelectStyles = (iRepresentTranslation) => ({
    container: (provided, state) => {

        return {
            ...provided,
            color: `var(--main-color-grey)`,
            fontFamily: `urw-din`,
            fontWeight: `normal`,
            fontSize: `12px`,
            lineHeight: `14px`,
            marginTop: `8px`,
            marginBottom: `8px`,
            "@media (min-width:1280px)": {
                maxWidth: `350px`,
                fontWeight: `500`,
                fontSize: `18px`,
                lineHeight: `22px`,
                marginTop: `18px`,
                marginBottom: `18px`
            },
        }
    },
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'var(--main-color-grey)' : 'var(--main-color-darkGrey)',
        fontWeight: state.isSelected ? 'var(--main-color-grey)' : 'intial',
        backgroundColor: state.isFocused ? 'var(--main-color-blue)' : 'transparent',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--main-color-blue)',
        }
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: `none`,
        borderWidth: `3px`,
        borderRadius: `0`,
        borderColor: state.isFocused ? `rgba(129, 145, 159, 0.8)` : `rgba(129, 145, 159, 0.3)`,
        padding: `1px`,
        '&:focus': {
            borderColor: `rgba(129, 145, 159, 0.8)`,
            outline: `none`
        },
        '&:hover': {
            borderColor: `rgba(129, 145, 159, 0.8)`,
            outline: `none`
        },
        "@media (min-width:1280px)": {
            padding: `3px`,
        }
    }),
    indicatorSeparator: () => {
        return {

        }
    },
    singleValue: (provided, state) => {

        return {
            ...provided,
            width: '100%',
            maxWidth: `calc(100% - 16px)`,
            textAlign: `left`,
            color: `var(--main-color-grey)`,
            fontWeight: `bold`,
            '&::before': {
                content: iRepresentTranslation,
                fontWeight: `normal`,
                color: `var(--main-color-darkGrey)`,
            }
        }
    },
    dropdownIndicator: (provided, state) => {
        const transform = state.isFocused ? `rotate(180deg)` : `initial`

        return {
            ...provided,
            transform,
            '& > svg > path': {
                fill: `var(--main-color-grey)`
            }
        }
    }
})

const Indicator = () => {

    return (
        <div>
            <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.41 24.0898L27 28.6698L31.59 24.0898L33 25.4998L27 31.4998L21 25.4998L22.41 24.0898Z" fill="#40525A"/>
            </svg>
        </div>
    )
}

export default function SelectOrganization({
    value,
    onChange,
    disabled
}) {
    const { t, i18n, ready } = useTranslation('formWidgets');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let optionsDefault = [
            {
                value: "contractor",
                label: t("organizationInput.options.contractor"), 
            },
            {
                value: "roadAgency",
                label: t("organizationInput.options.roadAgency"), 
            },
            {
                value: "municipality",
                label: t("organizationInput.options.municipality"), 
            },
            {
                value: "privateCompany",
                label: t("organizationInput.options.privateCompany"), 
            },
            {
                value: "other",
                label: t("organizationInput.options.other"), 
            }
        ];

        setOptions(options => [...optionsDefault])

    }, [ready]);



    return (
        <Select
            options={options}
            value={value}
            onChange={onChange}
            disabled={disabled}
            styles={SelectStyles(`'${t(`organizationInput.iRepresent`)} '`)}
            isSearchable={false}
            placeholder={t(`organizationInput.placeholder`)}
        />
    );
}