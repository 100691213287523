import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
    }

  body {
    margin: 0;
    padding: 0;
    margin: 0;
    font-family: 'urw-din', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    --main-color-grey: #40525A;
    --main-color-blue: #AECACE;
    --main-color-darkGrey: #81919F;
    --main-color-yellow: #EDC59E;
    --main-color-black: #000000;
    --main-color-green: #AECEBD;

    --bg-color-1: #40525A;
    --bg-color-2: #F9FAFA;
    --bg-color-3: #FFFFFF;
  }

  /* Scrollbar */
  *::-webkit-scrollbar {
    width: 4px;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--main-color-darkGrey) rgba(127, 144, 159, 0.2);
  }
  *::-webkit-scrollbar-track {
    background: rgba(127, 144, 159, 0.2);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--main-color-darkGrey) ;
    border-radius: 3px;
    border: transparent;
  }
`;
 
export default GlobalStyle;