import React from 'react'
import styled, { css, keyframes } from 'styled-components';

const LoaderModalOverlay = styled.div`
    position: fixed;

    top: 0;
    left: 0;
    
    width: 100vw;
    height: 100vh;

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(129, 145, 159, 0.6);

    @media (min-width:1280px) and (min-height: 750px) {
        padding: initial;
    }
`

const LoaderModalContentContainer = styled.div`
    background: #FFFFFF;

    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        width: 60%;
        height: 400px;
    }

    @media (min-width:1280px) and (min-height: 750px) {
        width: 44%;
        height: 400px;
    }
`

const LoaderModal = ({
    children
}) => {

    return(
        <LoaderModalOverlay>
            <LoaderModalContentContainer>
                {children}
            </LoaderModalContentContainer>
        </LoaderModalOverlay>
    );
}

export default LoaderModal;

const LoadingSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 65px;
`

const Spinner = styled.div`
    position: relative;

    width: 130px;
    height: 130px;

    border-color: var(--main-color-blue);
    border-width: 12px;
    border-style: solid;
    border-radius: 50%;

    div:nth-child(1) {
        position: absolute;
        top: -11px;
        left: -11px;
        z-index: 10;

        width: 130px;
        height: 130px;


        border-radius: 50%;
        border: 12px solid #81919F;
        border-color: #81919F transparent transparent transparent;
        animation: spinningRing1 1.2s linear infinite;
    }

    div:nth-child(2) {
        position: absolute;
        top: -11px;
        left: -11px;

        z-index: 9;

        width: 130px;
        height: 130px;


        border-radius: 50%;
        border: 12px solid #FFFFFF;
        border-color: #FFFFFF transparent transparent transparent;
        transform: rotate(5deg);
        animation: spinningRing2 1.2s linear infinite;
    }

    div:nth-child(3) {
        position: absolute;
        top: -11px;
        left: -11px;

        z-index: 9;

        width: 130px;
        height: 130px;


        border-radius: 50%;
        border: 12px solid #FFFFFF;
        border-color: #FFFFFF transparent transparent transparent;
        transform: rotate(-5deg);
        animation: spinningRing3 1.2s linear infinite;
    }




    @keyframes spinningRing1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spinningRing2 {
        0% {
            transform: rotate(5deg);
        }
        100% {
            transform: rotate(365deg);
        }
    }
    @keyframes spinningRing3 {
        0% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(355deg);
        }
    }
`

export const LoadingSpinner = () => {

    return (
        <LoadingSpinnerContainer>
            <Spinner>
                <div/>
                <div/>
                <div/>
            </Spinner>
        </LoadingSpinnerContainer>
    )
}