import { axiosInstance as axios } from '../axiosInstance';
import { BASE_URL } from '../url';

/**
 * Generates PDF link
 * @async
 * @function generatePDFLink
 * @param {string} bodyParams - an object containing the following parameters countryName, municipalityId, municipalityName, savingsMoneyAmount, savingsMoneyPercentage, savingsSaltAmount, savingsSaltPercentage, savingsCO2Amount, savingsCO2Percentage, language
 * @returns {Promise<Object>} The Object containing the URL for downloading the generated report
 * ```
 * {
 *   reportUrl: `/pdf/reports/generated-pdf-id-123456/report.pdf`
 * }
 * ```
*/
export async function generatePDFLink(bodyParams, language) {
  bodyParams["language"] = language;
  const payload = bodyParams;

  const { data } = await axios.post(`${BASE_URL}/pdf`, payload);

  return data;
}