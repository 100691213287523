import React from 'react'
import styled, { css, keyframes } from 'styled-components';

import { useTranslation } from 'react-i18next';

// Icons

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const popIn = keyframes`
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.5) ;
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) ;
    }
`;

const Label = styled.label`
    position: relative;
    display: flex;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

    padding-left: calc(22px + 3rem);

    min-height: 40px;

    color: var(--main-color-darkGrey);
    

    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (min-width:1280px) and (min-height: 750px) {
        max-width: 320px;

        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }
`;

const Indicator = styled.div`
  width: 22px;
  height: 22px;
  
  position: absolute;
  top: calc(50% - 11px);
  left: 15px;

  border: 2px solid;
  border-radius: 1em;
  border-color: rgba(129, 145, 159, 0.3);

  ${
    props => (
        props.checked 
        ? 
        css`
            color: var(--main-color-grey);
            border-color: var(--main-color-grey);
        `
        : 
        css`
            border-color: rgba(129, 145, 159, 0.3);
        `
        )
  };

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked {
    color: var(--main-color-grey);
  }

  ${Input}:checked + &::after {
    display: block;
    border-style: solid;
    border-radius: 50%;
    background-color: var(--main-color-grey);
    border-color: var(--main-color-grey);
    width: 0.7em;
    height: 0.7em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;

export default function AgreeToTermsCheckbox({
  onChange,
  name,
  disabled,
  checked
}) {
    const { t, i18n } = useTranslation('formWidgets');

    return (
        <Label 
            disabled={disabled}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: t(`agreeToTermsLabel`)
                }}
            />
        <Input
            type="checkbox"
            role="checkbox"
            name={name}
            disabled={disabled}
            onChange={onChange}
            checked={checked}
        />
        <Indicator
            checked={checked}
        />
        </Label>
    );
}
