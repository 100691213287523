// React component for the analysis fourth step:
// Desc: Input slider, static content
// Visitor sets the `numberOfTreatmentActions`

import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';

import useWindowSize from '../hooks/useWindowResize';

import styled from 'styled-components';
import { BackButton, NextCTA } from '../components/widgets/Buttons';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { _setAnalysisStage, _setNumberOfTreatmentActions } from '../slices/analysisSlice';

import sideImage from '../assets/analysis-step-4-img.png';
import { LoadingSpinner } from '../components/widgets/Loaders';

const PageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    overflow-y: auto;

    @media(min-width:1280px) and (min-height: 750px) {
        height: 100%;
    }
`

const StaticImageContainer = styled.div`
    width: 100%;
    height: 40vh;
    order: -1;

    img {
        height: 100%;
        width: 100%;

        object-fit: cover;
        object-position: bottom;
    }

    @media(min-width:1280px) and (min-height: 750px) {
        width: 50%;
        height: 100%;
        order: initial;
    }
`

const ControlsSection = styled.div`
    position: relative;

    width: 100%;
    height: 60vh;

    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;

    overflow-y: auto;

    .controlsSection__question {
        color: var(--main-color-grey);

        font-weight: normal;
        font-size: 21px;
        line-height: 25px;

        margin-bottom: 16px;
    }

    .buttons__wrapper {
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        margin-bottom: 24px;

        margin-top: 70px;

        .buttons__wrapper__idkBtn {
            display: block;

            border: transparent;
            outline: none;
            
            padding: 18px;

            background-color: var(--bg-color-2);
            color: var(--main-color-darkGrey);

            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;

            cursor: pointer;

            transition: .2s ease-in-out;

            margin-right: 4px;

            
        }

        button:last-child {
            margin-left: 4px;
        }

        @media(min-width:1280px) and (min-height: 750px) {
            justify-content: flex-start;

            margin-bottom: 0;

            @media (min-height: 820px) {
                margin-top: 131px;
            }

            .buttons__wrapper__idkBtn {
                margin-right: 17px;
            }

            button:last-child {
                width: fit-content;

                margin-left: 17px;
            }
        }
    }

    @media(min-width:1280px) and (min-height: 750px) {
        width: 50%;
        height: 100%;

        padding-bottom: 10px;

        .controlsSection__question {
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;

            margin-bottom: 32px;
        }
    }

    .controlsSection__sliderContainer {
        margin-top: 80px;
    }
`

const ControlsHeading = styled.div`
    position: relative;
    color: var(--main-color-blue);

    margin-bottom: 16px;

    @media(min-width:1280px) and (min-height: 750px) {
        @media (min-height: 820px) {
            margin-bottom: 108px;
        }
    }

    .controlsHeading__currentStepNo {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: 600;
            font-size: 160px;
            line-height: 192px;
        } 
    }

    .controlsHeading__stepsTotal {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        } 

    }
`


const AnalysisStepFourPage = () => {
    const { t, i18n, ready } = useTranslation('analysis-step-4');

    const dispatch = useDispatch();
    const { globalState, mapState, analysisState } = useSelector(state => state);

    let history = useHistory();

    const { width, height } = useWindowSize();


    const handleNextStep = () => {
        dispatch(_setAnalysisStage('input_percentage_of_actions'));
        history.push('/analysis-step-5');
    }

    const handleGoBack = () => {
        dispatch(_setAnalysisStage('show_stations_data'));

        history.goBack();
    }

    useEffect(() => {
        if (analysisState.analysisStage === 'input_percentage_of_actions' || analysisState.analysisStage === 'show_stations_data') {
            dispatch(_setAnalysisStage('input_num_of_actions'));
        } else if (analysisState.analysisStage !== 'input_num_of_actions') {
            history.push('/')
        }
    }, []);

    return (
        <PageContainer>
            <ControlsSection>
                <ControlsHeading>
                    <span
                        className="controlsHeading__currentStepNo"
                    >
                        04
                    </span>
                    <span
                        className="controlsHeading__stepsTotal"
                    >
                        /6
                    </span>
                    <BackButton 
                        onClick={() => handleGoBack()}
                    />
                </ControlsHeading>
                {
                ready
                ?
                <>
                <div
                    className="controlsSection__question"
                    dangerouslySetInnerHTML={{
                        __html: t(`subheading`)
                    }}
                />
                <div
                    className="controlsSection__sliderContainer"
                >
                    <Slider 
                        min={0} 
                        max={100} 
                        step={10}
                        value={analysisState.numberOfTreatmentActions}
                        onChange={value => dispatch(_setNumberOfTreatmentActions(value))}
                        marks={{ 
                            0: '0',
                            10: (width < 1280 && height < 750) ? '' : '10',
                            20: '20',
                            30: (width < 1280 && height < 750) ? '' : '30',
                            40: '40',
                            50: (width < 1280 && height < 750) ? '' : '50',
                            60: '60',
                            70: (width < 1280 && height < 750) ? '' : '70',
                            80: '80', 
                            90: (width < 1280 && height < 750) ? '' : '90', 
                            100: '100' 
                        }}
                        railStyle={{
                            backgroundColor: 'var(--main-color-blue)'
                        }}
                        trackStyle={{
                            backgroundColor: 'var(--main-color-grey)'
                        }}
                        handleStyle={{
                            width: '22px',
                            height: '22px',
                            marginTop: '-9px',
                            borderColor: '#FFFFFF',
                            borderWidth: '4px',
                            backgroundColor: 'var(--main-color-grey)',
                            boxShadow: '0px 2px 20px #AECACE',
                            zIndex: '10'
                        }}
                        dotStyle={{
                            backgroundColor: 'var(--main-color-blue)',
                            borderColor: 'var(--main-color-blue)',
                        }}
                        activeDotStyle={{
                            backgroundColor: 'var(--main-color-grey)',
                            borderColor: 'var(--main-color-grey)',
                        }}
                    />
                </div>
                <div
                    className="buttons__wrapper"
                >
                    <button
                        className="buttons__wrapper__idkBtn"
                        onClick={() => {
                            dispatch(_setNumberOfTreatmentActions(50));
                            handleNextStep();
                        }}
                    >
                        {t(`idkBtn`)}
                    </button>
                    <NextCTA
                        labelText={t(`nextCTA`)}
                        onClick={() => handleNextStep()}
                        disabled={analysisState.numberOfTreatmentActions <= 0}
                    />
                </div>
                </>
                :
                <LoadingSpinner />
                }
            </ControlsSection>
            <StaticImageContainer>
                <img 
                    src={sideImage}
                />
            </StaticImageContainer>
        </PageContainer>
    );
}

export default AnalysisStepFourPage;