import { axiosInstance as axios } from '../axiosInstance';
import { BASE_URL } from '../url';


/**
 * Fetches the list of supported countries with a name 
 * and a github style shortcode for the emoji flag 
 * @async
 * @function fetchCountries
*/
export async function fetchCountries() {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/countries`
    );

    return data;
}

/**
 * Fetches all the data associated with a specific country.
 * @async
 * @function fetchAllCountryData
 * @param {string} countryName - The name of the country
 * 
*/
export async function fetchAllCountryData(countryName) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}`
    );

    return data;
}

/**
 * Fetches a list of all the names and ids of the municipalities in a `country`
 * @async
 * @function fetchCountrysMunicipalities
 * @param {string} countryName - The name of the country
 * 
*/
export async function fetchCountrysMunicipalities(countryName) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/listMunicipalities`
    );

    return data;
}

/**
 * Fetches all boundaries and roads data for a specific municipality via its human readable name.
 * @async
 * @function fetchMunicipalityDataByName
 * @param {string} countryName - The name of the country
 * @param {string} municipalityName - The name of the municipality
 * 
*/
export async function fetchMunicipalityDataByName(countryName, municipalityName) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/${municipalityName}`
    );

    return data;
}

/**
 * Fetches all boundaries and roads data for a specific municipality via its id.
 * @async
 * @function fetchMunicipalityDataById
 * @param {string} countryName - The name of the country
 * @param {number} municipalityId - The id of the municipality
 * @returns Array with a single item: an Object of `type` "Feature" with `properties` (contains roads data) and `geometry` (contains municipality's boundaries)
 * ```
 * [
 *   {
 *      "type":"Feature",
 *      "properties": {...},
 *      "geometry": {
 *         "type":"MultiPolygon",
 *         "coordinates": 
 *         [
 *           [
 *             [
 *               [18.335707421045175,59.366986641647024], ...
 *             ]
 *           ]
 *         ]
 *      }
 *   }
 * ]
 * ```
*/
export async function fetchMunicipalityDataById(countryName, municipalityId) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/municipalityById/${municipalityId}`
    );

    return data;
}

/**
 * Get the centerpoint for a certain municipality in a certain country through its `id` (or `kid`)
 * @async
 * @function fetchMunicipalityCenterPoint
 * @param {string} countryName - The name of the country
 * @param {number} municipalityId - The id of the municipality
 * @returns {Promise<Array>} The array of center points
 * ```
 * [
 *   {
 *     "type":"Feature",
 *     "properties": { 
 *       "HubName":"216",
 *       "HubDist":5.793939329418105,
 *       "KKOD":414,
 *       "KATEGORI":"Kommun","KOMMUNKOD":182,
 *       "KOMMUNNAMN":"Nacka","ANTAL VVIS":1
 *     },
 *     "geometry": {
 *       "type":"Point",
 *       "coordinates":[683641.2931817133,6577949.722576503]
 *     }
 *   }
 * ]
 * ```
*/
export async function fetchMunicipalityCenterPoint(countryName, municipalityId) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/${municipalityId}/centerPoint`
    );

    return data;
}

/**
 * Get the a list of all the stations in this municipality through its `id` (or `kid`)
 * @async
 * @function fetchMunicipalityStations
 * @param {string} countryName - The name of the country
 * @param {number} municipalityId - The id of the municipality
 * @returns {Promise<Array>} The array of stations
 * ```
 * [
 *   {
 *     "type":"Feature",
 *     "properties": { 
 *       "vvis_id":218,
 *       "st_y":59.32912064,
 *       "st_x":18.31434059,
 *       "name":"Gustavsberg",
 *       "NEAR_FID":318103,
 *       "NEAR_DIST":0.00018303073,"adt":15267,
 *       "ZonalSt_VV":0,
 *       "Region":null,
 *       "KKOD":414,
 *       "KATEGORI":"Kommun",
 *       "KOMMUNKOD":182,
 *       "KOMMUNNAMN":"Nacka"
 *     },
 *     "geometry":{ 
 *       "type":"Point",
 *       "coordinates":[18.31434059,59.32912064]
 *     }
 *   }, ...
 * ]
 * ```
*/
export async function fetchMunicipalityStations(countryName, municipalityId) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/${municipalityId}/stations`
    );

    return data;
}

/**
 * Get the a list of all the road lines in the municipality through its `id` (or `kid`)
 * @async
 * @function fetchMunicipalityStations
 * @param {string} countryName - The name of the country
 * @param {number} municipalityId - The id of the municipality
 * @returns {Promise<Array>} The array of stations
 * ```
 * [
  {
    "type": "Feature",
    "properties": {
      "Klass_181": null,
      "MCODE": 123,
      "MCODE_1": 123
    },
    "geometry": {
      "type": "MultiLineString",
      "coordinates": [
        [
          [11.995697021484375, 57.715518376174266],
          ...
        ],
        ...
      ]
    }
  }
]
 *  
 * ```
*/
export async function fetchMunicipalityRoadLines(countryName, municipalityId) {
    const { data } = await axios.get(
        `${BASE_URL}/api/v1/country/${countryName}/${municipalityId}/roads`
    );

    return data;
}

/**
 * Calculate a municipalities possible savings every year.
 * @async
 * @function calculateMunicipalitySavings
 * @param {string} countryName - The name of the country
 * @param {number} municipalityId - The id of the municipality
 * @param {number} nrOfTreatmentActions - The number of annual treatment actions
 * @param {number} percentageOfRoadCovered - Percentage of road covered per action
 * @returns {Promise<Object>} The Object containing savings estimates and current costs for a municipality 
 * ```
 * {
 *   savings: {
 *     cost: 1234,
 *     salt: 1234,
 *     co2: 1234
 *   },
 *   current: {
 *     cost: 4321,
 *     salt: 4321,
 *     co2: 4321
 *   }
 * }
 * ```
*/
export async function calculateMunicipalitySavings(
    countryName, 
    municipalityId,
    nrOfTreatmentActions,
    percentageOfRoadCovered
) {
    const payload = {
        country: countryName,
        kid: municipalityId,
        nrOfTreatmentActions,
        percentageOfRoadCovered
    }

    const { data } = await axios.post(
        `${BASE_URL}/api/v1/calculate`,
        payload
    );

    return data;
}