// React component for the analysis results for the country that has no data on the country level in the API
// Desc: Contact form with multiple inputs;
// User fills in the form, send the request, loading modal appears, downloading static PDF link becomes active

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import validator from 'validator';

import styled from 'styled-components';
import { BackButton, NextCTA } from '../components/widgets/Buttons';

import 'rc-slider/assets/index.css';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { AnalysisPartialSendMailAllowDownload, resetCountryAndMunicipality, _setAnalysisStage, _setAnalysisStateValueGeneric } from '../slices/analysisSlice';
import { _resetUserInfoFields, _setUserInfoField } from '../slices/userInfoSlice';

// Widgets
import { EmailInput, NameInput, PositionInput, PhoneInput } from '../components/widgets/InputWidgets';
import AgreeToTermsCheckbox from '../components/widgets/CheckboxWidgets';
import SelectOrganization from '../components/widgets/SelectWidgets';
import LoaderModal, { LoadingSpinner } from '../components/widgets/Loaders';
import { DownLoadLink } from '../components/widgets/Links';
import { LinearInterpolator } from 'react-map-gl';
import { _setViewport } from '../slices/mapSlice';
import useWindowSize from '../hooks/useWindowResize';

import cardDenmarkImg from '../assets/get-in-touch-soren-pedersen.png';
import cardOtherImg from '../assets/get-in-touch-emil-danielsson.png';

const PageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    overflow-y: auto;

    @media(min-width:1280px) {
        height: 100%;
    }
`

const ContactPanelSection = styled.div`
    width: 100%;
    height: 50%;

    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 24px;

    background-color: var(--bg-color-3);

    & > h2 {
        color: var(--main-color-grey);
        font-weight: normal;
        font-size: 21px;
        line-height: 25px;
    }

    & > h3 {
        color: var(--main-color-grey);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
    }

    .contactPanelSection__card {

        & > h4 {
            color: var(--main-color-grey);
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
        }

        .contactPanelSection__card__info {
            display: flex;

            .contactPanelSection__card__info__imgDiv {
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 30px;
                    height: 30px;

                    z-index: 2;
                }
                &::after {
                    position: absolute;
                    top: calc(50% - 18px);
                    left: 3px;

                    z-index: 1;

                    content: "";
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: var(--main-color-blue);
                }

                margin-right: 13px;
            }

            .contactPanelSection__card__info__infoDiv {
                display: flex;
                flex-direction: column;
                justify-content: center;
                h4 {
                    display: none;
                }
                h3 {
                    color: var(--main-color-grey);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 0;
                    margin-top: 0;
                }
                a {
                    display: block;
                    text-decoration: none;
                    color: var(--main-color-grey);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }



    @media(min-width:1280px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 35%;
        height: 100%;
        
        background-color: var(--bg-color-2);

        padding: initial;
        padding-left: 1.5rem;

        padding-top: 220px;

        @media (min-height: 820px) {
            padding-top: 296px;
        }

        & > h2 {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        }

        & > h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            margin-bottom: 48px;
        }

        .contactPanelSection__card {
            margin-bottom: 48px;

            & > h4 {
                color: var(--main-color-grey);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
            }

            .contactPanelSection__card__info {

                .contactPanelSection__card__info__imgDiv {
                    img {
                        width: 64px;
                        height: 64px;

                        z-index: initial;
                    }
                    &::after {
                        display: none;
                    }

                    margin-right: 21px;
                }

                .contactPanelSection__card__info__infoDiv {
                    h4 {
                        display: block;
                        color: var(--main-color-grey);
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    h3 {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    a {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }

    @media(min-width: 1600px) {
        padding-left: 100px;
    }
`

const ControlsSection = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;

    overflow-y: auto;

    .controlsSection__heading {
        color: var(--main-color-grey);

        h2 {
            font-weight: normal;
            font-size: 21px;
            line-height: 25px;

            margin-bottom: 16px;

            span {
                color: var(--main-color-blue);
            }
        }

        div {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;

            white-space: pre-line;

            margin-bottom: 8px;

            button {
                display: inline;
                border: none;
                background: none;
                font-size: inherit;
                font-weight: bold;
                color: var(--main-color-blue);

                padding: 0;

                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
                &:focus {
                    text-decoration: underline;
                    outline: none;
                }
            }
        }
    }

    .controlsSection__form {

    }

    .nextCTA__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        margin-bottom: 24px;

        margin-top: 18px;

        button {
            width: 100%;

            margin-bottom: 16px;
        }
        a {
            width: 100%;
        }
    }

    @media(min-width:1280px) {
        width: 65%;
        height: 100%;

        padding-bottom: 10px;

        .controlsSection__heading {

            h2 {
                font-weight: bold;
                font-size: 40px;
                line-height: 48px;

                margin-bottom: 36px;
            }

            div {
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;

                margin-bottom: 36px;
            }
        }

        .controlsSection__form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .nextCTA__wrapper {
            margin-bottom: 0;

            margin-top: 24px;

            @media (min-height: 820px) {
                margin-top: 92px;
            }

            button {
                width: fit-content;

                margin-right: 32px;
                margin-bottom: initial;
            }
            a {
                width: initial;
            }
        }
    }

`

const ControlsHeading = styled.div`
    position: relative;
    color: var(--main-color-blue);

    margin-bottom: 16px;

    @media(min-width:1280px) {

        @media (min-height: 820px) {
            margin-bottom: 108px;
        }
    }

    .controlsHeading__currentStepNo {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(min-width:1280px) {
            font-weight: 600;
            font-size: 160px;
            line-height: 192px;
        } 
    }

    .controlsHeading__stepsTotal {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media(min-width:1280px) {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        } 

    }
`


const AnalysisResultsLackData = () => {
    const { t, i18n, ready } = useTranslation('analysis-results-lack-data');

    const dispatch = useDispatch();
    const { globalState, mapState, analysisState, userInfo } = useSelector(state => state);

    let history = useHistory();

    const { width, height } = useWindowSize()

    const handleNextStep = () => {
        dispatch(AnalysisPartialSendMailAllowDownload(
            () => setIsDownloadAllowed(true),
            t(`toasts.errorToastHeading`),
            t(`toasts.errorToastBodyGeneric`),
            t(`toasts.errorToastBodyEmail`)
        ));
    }

    // Download becomes available after submitting the form
    const [isDownloadAllowed, setIsDownloadAllowed] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const [countDown, setCountDown] = useState(5)


    // Simple validation
    const [isValid, setIsValid] = useState(false);


    const handleGoBack = () => {
        dispatch(resetCountryAndMunicipality());
        dispatch(_resetUserInfoFields());

        dispatch(_setViewport({
            width: '100%',
            height: '100%',
            latitude: 63,
            longitude: 22.4376,
            zoom: width > 640 ? 3.5 : 0.6,
            transitionInterpolator: new LinearInterpolator(),
            transitionDuration: 1000
        }));

        history.goBack();
    }

    useEffect(() => {
        if (!userInfo.name || !userInfo.email || (userInfo.email && !validator.isEmail(userInfo.email)) || !userInfo.agreedToTerms) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [userInfo]);

    useEffect(() => {
        let interval = null;

        if (modalShown) {
            interval = setInterval(() => {
                setCountDown(cd => cd - 1)
            }, 1000);               
        }
        
        return () => interval ? clearInterval(interval) : null;
    }, [modalShown]);

    useEffect(() => {
        if (countDown === 0) {
            dispatch(_setAnalysisStage('prestart'));
            dispatch(_setAnalysisStateValueGeneric({
                field: `selectedAreaType`,
                value: `municipality`
            }));
    
            dispatch(_setAnalysisStateValueGeneric({
                field: `selectedCountryName`,
                value: ``
            }));
    
            history.push('/');
        }
    }, [countDown])

    return (
        <>
        <PageContainer>
            <ControlsSection>
                <ControlsHeading>
                    <span
                        className="controlsHeading__currentStepNo"
                    >
                        02
                    </span>
                    <span
                        className="controlsHeading__stepsTotal"
                    >
                        /2
                    </span>
                    {
                        !modalShown
                        ?
                        <BackButton
                            onClick={() => handleGoBack()}
                        />
                        :
                        null
                    }
                </ControlsHeading>
                {
                ready
                ?
                <>
                <div
                    className="controlsSection__heading"
                >
                    <h2>
                        {t(`heading`)}<span>{analysisState.countryDisplayName}</span>
                    </h2>
                    <div>
                        {t(`subheading__para1`)}
                        <button
                            onClick={() => handleGoBack()}
                        >
                            {t(`subheading__para1__button`)}
                        </button>
                        <br/>
                        {t(`subheading__para2`)}
                    </div>
                </div>
                <form
                    className="controlsSection__form"
                >
                    <NameInput
                        value={userInfo.name}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `name`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <EmailInput
                        value={userInfo.email}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `email`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <PhoneInput 
                        value={userInfo.phoneNumber}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `phoneNumber`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <SelectOrganization 
                        value={userInfo.organizationType}
                        onChange={(v) => {
                            dispatch(_setUserInfoField({
                                field: `organizationType`, 
                                value: v
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <PositionInput 
                        value={userInfo.position}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `position`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <AgreeToTermsCheckbox 
                        checked={userInfo.agreedToTerms}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `agreedToTerms`, 
                                value: e.target.checked
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                </form>
                <div
                    className="nextCTA__wrapper"
                >
                    <NextCTA
                        labelText={t(`submitCTA`)}
                        onClick={() => handleNextStep()}
                        disabled={!isValid}
                    />
                    <DownLoadLink
                        labelText={t(`downloadCTA`)}
                        disabled={!isDownloadAllowed}
                        href={isDownloadAllowed ? `/pdf/reports/static/${(i18n.language && i18n.language.length === 2) ? i18n.language : `en`}/report.pdf` : null}
                        onClick={() => setModalShown(true)}
                    />
                </div>
                </>
                :
                <LoadingSpinner/>
                }
            </ControlsSection>
            <ContactPanelSection>
                {
                ready 
                ?
                <>
                <h2>
                    {t(`getInTouchSection.heading`)}
                </h2>
                <h3>
                    {t(`getInTouchSection.subheading`)}
                </h3>
                <div 
                    className="contactPanelSection__card"
                >
                    <h4>
                        {t(`getInTouchSection.cardDenmark.title`)}
                    </h4>
                    <div
                        className="contactPanelSection__card__info"
                    >
                        <div
                            className="contactPanelSection__card__info__imgDiv"
                        >
                            <img
                                src={cardDenmarkImg}
                            />
                        </div>
                        <div
                            className="contactPanelSection__card__info__infoDiv"
                        >
                            <h4>
                                {t(`getInTouchSection.cardDenmark.position`)}
                            </h4>
                            <h3>
                                {t(`getInTouchSection.cardDenmark.name`)}
                            </h3>
                            <a
                                href="mailto:soren.pedersen@klimator.dk"
                            >
                                soren.pedersen@klimator.dk
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="contactPanelSection__card"
                >
                    <h4>
                        {t(`getInTouchSection.cardOther.title`)}
                    </h4>
                    <div
                        className="contactPanelSection__card__info"
                    >
                        <div
                            className="contactPanelSection__card__info__imgDiv"
                        >
                            <img
                                src={cardOtherImg}
                            />
                        </div>
                        <div
                            className="contactPanelSection__card__info__infoDiv"
                        >
                            <h4>
                                {t(`getInTouchSection.cardOther.position`)}
                            </h4>
                            <h3>
                                {t(`getInTouchSection.cardOther.name`)}
                            </h3>
                            <a
                                href="mailto:emil.danielsson@klimator.se"
                            >
                                emil.danielsson@klimator.se
                            </a>
                        </div>
                    </div>
                </div>
                </>
                :
                null
                }
            </ContactPanelSection>
        </PageContainer>
        {
            userInfo.isSumbitUserInfoLoading
            ?
            <LoaderModal>
                <div
                    className="analysisLackData__loadingModalContent"
                >
                    <LoadingSpinner />
                </div>
            </LoaderModal>
            :
            null
        }
        {
            modalShown
            ?
            <LoaderModal>
            <div
                    className="analysisLackData__finishedModalContent"
                >
                    <div
                        style={{
                            position: `relative`,
                            marginTop: `16px`
                        }}
                    >
                        <div
                            className="indicator__background"
                        />
                        <div>
                        <div className="indicator__borders"><div/></div>
                        </div>
                        <svg 
                            style={{
                                position: `absolute`,
                                top: `36px`,
                                left: `calc(50% - 22px)`
                            }}
                        width="54" height="51" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 22.5L19.6667 41L49 4" stroke="#AECACE" stroke-width="12"/>
                        </svg>
                    </div>
                    <h2>
                        {t(`finishedModalHeading`)}
                    </h2>
                    <div>
                        {t(`finishedModalBody`, {delay: countDown})}
                    </div>
                </div>
            </LoaderModal>
            :
            null
        }
        </>
    );
}

export default AnalysisResultsLackData;