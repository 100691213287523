import { createSlice } from '@reduxjs/toolkit';
import { calculateMunicipalitySavings, fetchMunicipalityCenterPoint, fetchMunicipalityDataById, fetchMunicipalityRoadLines, fetchMunicipalityStations } from '../api/endpoints/apiMain';
import { sendMail } from '../api/endpoints/mailman';
import { generatePDFLink } from '../api/endpoints/pdfGeneration';
import { _setCenterpointSourceVisible, _setRoadlinesLayerVisible, _setStationsSourceVisible } from './mapSlice';
import { _resetUserInfoFields, _setUserInfoField } from './userInfoSlice';

// Toasts
import { toast } from 'react-toastify';

// Emails
let denmarkEmail = process.env.REACT_APP_TEST_EMAIL || 'leads_dk@klimator.se';
let otherEmail = process.env.REACT_APP_TEST_EMAIL || 'leads_se@klimator.se';



// Default state
const defaultState = {
    // Enum: 
    // ['prestart', 'choose_location', 'show_roads_data', 'show_stations_data', 
    // 'input_num_of_actions', 'input_percentage_of_actions', 
    // 'form_all_steps', 
    // 'form_lack_data', 'form_no_data'
    // 'finished']
    analysisStage: 'prestart',
    // Enum: ['country', 'municipality']
    selectedAreaType: '',
    selectedCountryName: '',
    countryDisplayName: '',
    countryCode: '',
    countrySupported: '',
    selectedMunicipalityName: '',
    selectedMunicipalityId: '',
    municipalityBoundaries: [],
    roadNetWorkData: {
        roadsNetworkTotalLength: '',
        primaryRoadsLength: '',
        bikeLanesLength: '',
        residentialRoadsLength: '',
    },
    isMunicipalityBoundariesLoading: false,
    municipalityBoundariesError: null,
    municipalityRoadLines: [],
    isMunicipalityRoadlinesLoading: false,
    municipalityRoadLinesError: null,
    municipalityStations: [],
    municipalityCenterPoint: {},
    averageDistanceToStation: '',
    coverageGood: false,
    isMunicipalityStationsAndCenterPointsLoading: false,
    municipalityStationsAndCenterPointsError: null,
    numberOfTreatmentActions: 0,
    percentagePerTreatmentActions: 0,
    savingsData: '',
    isCalculationsLoading: false,
    calculationsError: null,
    pdfLink: '',
    isPDFLinkLoading: false,
    PDFLinkError: null
}

const initialState = defaultState;

// Initialize state and define reducers
const analysisState = createSlice({
    name: 'analysisState',
    initialState,
    reducers: {
        _setAnalysisStage(state, action) {
            state.analysisStage = action.payload;
        },
        _setAnalysisStateValueGeneric(state, action) {
            const { field, value } = action.payload;
            state[field] = value;
        },
        _setMunicipalityBoundaries(state, action) {
            state.municipalityBoundaries = [...action.payload];
        },
        _setRoadNetworkData(state, action) {
            state.roadNetWorkData = {...action.payload}
        },
        _setMunicipalityRoadlines(state, action) {
            state.municipalityRoadLines = [...action.payload];
        },
        _setMunicipalityStations(state, action) {
            state.municipalityStations = [...action.payload]
        },
        _setMunicipalityCenterpoint(state, action) {
            state.municipalityCenterPoint = {...action.payload}
        },
        _setNumberOfTreatmentActions(state, action) {
            state.numberOfTreatmentActions = action.payload;
        },
        _setPercentagePerTreatmentActions(state, action) {
            state.percentagePerTreatmentActions = action.payload;
        },
        _setSavingsData (state, action) {
            state.savingsData = action.payload;
        },
        _setPDFLink (state, action) {
            state.pdfLink = action.payload;
        },
    }
});

export const {
    _setAnalysisStage,
    _setAnalysisStateValueGeneric,
    _setMunicipalityBoundaries,
    _setRoadNetworkData,
    _setMunicipalityRoadlines,
    _setMunicipalityStations,
    _setMunicipalityCenterpoint,
    _setNumberOfTreatmentActions,
    _setPercentagePerTreatmentActions,
    _setSavingsData,
    _setPDFLink
} = analysisState.actions;


export const fetchAndSetMunicipalityBordersAndRoadsData = (countryName, id) => async (dispatch, getState) => {
    try {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: true
        }));

        const municipalityData = await fetchMunicipalityDataById(countryName, id);

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedAreaType`,
            value: `municipality`
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedCountryName`,
            value: countryName
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedMunicipalityName`,
            value: municipalityData[0].properties.MNAME
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedMunicipalityId`,
            value: id
        }));    

        dispatch(_setRoadNetworkData({
            roadsNetworkTotalLength: municipalityData[0].properties.TOTAL_LENG,
            primaryRoadsLength: municipalityData[0].properties.PRIMARY,
            bikeLanesLength: municipalityData[0].properties.BIKE,
            residentialRoadsLength: municipalityData[0].properties.RESIDENT,
        }));


        dispatch(_setMunicipalityBoundaries(municipalityData));


        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: false
        }));
    } catch (err) {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `municipalityBoundariesError`,
            value: err.toString()
        }));

        console.log(err);
    }
}

export const fetchAndSetCountryBoundaries = (countryName, countryCode, countrySupported, countryDisplayName) => async (dispatch, getState) => {
    const state = getState();
    try {

        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: true
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedAreaType`,
            value: `country`
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `selectedCountryName`,
            value: countryName
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `countryCode`,
            value: countryCode
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `countrySupported`,
            value: countrySupported
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `countryDisplayName`,
            value: countryDisplayName
        }));

        if (state.analysisState.selectedMunicipalityName) {
            dispatch(_setAnalysisStateValueGeneric({
                field: `selectedMunicipalityName`,
                value: ''
            }));
    
            dispatch(_setAnalysisStateValueGeneric({
                field: `selectedMunicipalityId`,
                value: ''
            }));    
    
            dispatch(_setRoadNetworkData({
                roadsNetworkTotalLength: '',
                primaryRoadsLength: '',
                bikeLanesLength: '',
                residentialRoadsLength: '',
            }));
    
    
            dispatch(_setMunicipalityBoundaries([]));
        }


        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: false
        }));
    } catch (err) {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityBoundariesLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `municipalityBoundariesError`,
            value: err.toString()
        }));

        console.log(err);
    }
}

export const resetCountryAndMunicipality = () => async (dispatch, getState) => {
    dispatch(_setAnalysisStateValueGeneric({
        field: `selectedAreaType`,
        value: `municipality`
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `selectedCountryName`,
        value: ``
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `countryCode`,
        value: ``
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `countrySupported`,
        value: ``
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `countryDisplayName`,
        value: ''
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `selectedMunicipalityName`,
        value: ``
    }));

    dispatch(_setAnalysisStateValueGeneric({
        field: `selectedMunicipalityId`,
        value: ``
    }));    

    dispatch(_setRoadNetworkData({
        roadsNetworkTotalLength: ``,
        primaryRoadsLength: ``,
        bikeLanesLength: ``,
        residentialRoadsLength: ``,
    }));


    dispatch(_setMunicipalityBoundaries([]));
}

export const fecthAndSetMunicipalityRoadlines = () => async (dispatch, getState) => {
    try {
        const state = getState();

        if (state.mapState.roadlinesSourceAndLayer) return 

        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityRoadlinesLoading`,
            value: true
        }));

        const roads = await fetchMunicipalityRoadLines(state.analysisState.selectedCountryName, state.analysisState.selectedMunicipalityId);

        if (roads && roads.length > 0) {
            dispatch(_setMunicipalityRoadlines(roads));
        }


        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityRoadlinesLoading`,
            value: false
        }));
    } catch (err) {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityRoadlinesLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `municipalityRoadlinesError`,
            value: err.toString()
        }));

        console.log(err);
    }
}

export const fetchAndSetMunicipalityStationsAndCenterpoint = (nextPageCallback) => async (dispatch, getState) => {
    try {
        const state = getState();

        if (state.mapState.roadlinesSourceAndLayer && state.mapState.stationsSource && state.mapState.centerpointSource) {
            dispatch(_setRoadlinesLayerVisible(false));
            dispatch(_setStationsSourceVisible(true));
            dispatch(_setCenterpointSourceVisible(true));

            nextPageCallback();

            return 
        }

        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityStationsAndCenterPointsLoading`,
            value: true
        }));

        const stations = await fetchMunicipalityStations(state.analysisState.selectedCountryName, state.analysisState.selectedMunicipalityId);

        const centerpoint = await fetchMunicipalityCenterPoint(state.analysisState.selectedCountryName, state.analysisState.selectedMunicipalityId);

        dispatch(_setMunicipalityStations(stations));
        dispatch(_setMunicipalityCenterpoint(centerpoint));
        dispatch(_setAnalysisStateValueGeneric({
            field: `coverageGood`,
            value: centerpoint.properties.hasGoodCoverage
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `averageDistanceToStation`,
            value: centerpoint.properties.averageDistanceToStation
        }));

        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityStationsAndCenterPointsLoading`,
            value: false
        }));

        if (state.analysisState.municipalityRoadLines && state.analysisState.municipalityRoadLines.length > 0) {
            dispatch(_setRoadlinesLayerVisible(false));
        }

        nextPageCallback();
    } catch (err) {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isMunicipalityStationsAndCenterPointsLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `municipalityStationsAndCenterPointsError`,
            value: err.toString()
        }));

        console.log(err);
    }
}


export const AnalysisFullCalculateSendMailGeneratePDF = (
    nextPageCallback, language,
    errorToastHeading,
    errorToastBodyGeneric,
    errorToastBodyEmail
) => async (dispatch, getState) => {
    let state = getState();
    try {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isCalculationsLoading`,
            value: true
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `isPDFLinkLoading`,
            value: true
        }));
        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: true
        }));

        let userInfoPayload = {
            name: state.userInfo.name,
            email: state.userInfo.email,
        };

        if (state.userInfo.phoneNumber) userInfoPayload.phone = state.userInfo.phoneNumber
        if (state.userInfo.organizationType) userInfoPayload.represent = state.userInfo.organizationType.value
        if (state.userInfo.position) userInfoPayload.jobTitle = state.userInfo.position

        userInfoPayload.country = state.analysisState.selectedCountryName;
        userInfoPayload.municipality = state.analysisState.selectedMunicipalityName;
        // Email depending on the region
        userInfoPayload.klimatorEmail = state.analysisState.selectedCountryName === 'Denmark' ? denmarkEmail : otherEmail
        
        const sendEmailResponse = await sendMail(userInfoPayload);

        if (!sendEmailResponse) throw new Error(`Error while sending email`);

        const calculations = await calculateMunicipalitySavings(
            state.analysisState.selectedCountryName.toLowerCase(),
            state.analysisState.selectedMunicipalityId,
            state.analysisState.numberOfTreatmentActions,
            state.analysisState.percentagePerTreatmentActions
        );

        dispatch(_setSavingsData(calculations));

        let costPercentage = (calculations.savings.cost && calculations.current.cost) ? Math.round(calculations.savings.cost / calculations.current.cost * 100) : 0;
        let saltPercentage = (calculations.savings.salt && calculations.current.salt) ? Math.round(calculations.savings.salt / calculations.current.salt * 100) : 0;
        let co2Percentage = (calculations.savings.co2 && calculations.current.co2) ? Math.round(calculations.savings.co2 / calculations.current.co2 * 100) : 0;

        const queryBody = {
          countryName: state.analysisState.selectedCountryName,
          municipalityId: state.analysisState.selectedMunicipalityId,
          municipalityName: state.analysisState.selectedMunicipalityName,
          savingsMoneyAmount: calculations.savings.cost,
          savingsMoneyPercentage: costPercentage,
          savingsSaltAmount: calculations.savings.salt,
          savingsSaltPercentage: saltPercentage,
          savingsCO2Amount: calculations.savings.co2,
          savingsCO2Percentage: co2Percentage,
        };

        const { reportUrl } = await generatePDFLink(queryBody, language);

        dispatch(_setPDFLink(reportUrl));

        dispatch(_setAnalysisStage('finished'));

        nextPageCallback();        

        dispatch(_setAnalysisStateValueGeneric({
            field: `isCalculationsLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `isPDFLinkLoading`,
            value: false
        }));
        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: false
        }));
    } catch (err) {
        dispatch(_setAnalysisStateValueGeneric({
            field: `isCalculationsLoading`,
            value: false
        }));
        dispatch(_setAnalysisStateValueGeneric({
            field: `isPDFLinkLoading`,
            value: false
        }));
        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: false
        }));
        console.log(err)
        if (err.response && err.response.data && err.response.data.error === `"email" must be a valid email`) {
            toast.error(<><h4>{errorToastHeading}</h4><div>{errorToastBodyEmail}</div></>)
        } else {
            toast.error(<><h4>{errorToastHeading}</h4><div>{errorToastBodyGeneric}</div></>)
        }
    }
}

export const AnalysisPartialSendMailAllowDownload = (
    allowDownloadCallback,
    errorToastHeading,
    errorToastBodyGeneric,
    errorToastBodyEmail
) => async (dispatch, getState) => {
    let state = getState();
    try {
        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: true
        }));

        let userInfoPayload = {
            name: state.userInfo.name,
            email: state.userInfo.email,
        };

        if (state.userInfo.phoneNumber) userInfoPayload.phone = state.userInfo.phoneNumber
        if (state.userInfo.organizationType) userInfoPayload.represent = state.userInfo.organizationType.value
        if (state.userInfo.position) userInfoPayload.jobTitle = state.userInfo.position

        userInfoPayload.country = state.analysisState.selectedCountryName;
        userInfoPayload.municipality = '_';

        // Derive email depending on the region
        userInfoPayload.klimatorEmail = state.analysisState.selectedCountryName === 'Denmark' ? denmarkEmail : otherEmail
        
        const sendEmailResponse = await sendMail(userInfoPayload);

        if (!sendEmailResponse) throw new Error(`Error while sending email`);

        allowDownloadCallback();       


        dispatch(_resetUserInfoFields());

        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: false
        }));
    } catch (err) {
        dispatch(_setUserInfoField({
            field: `isSumbitUserInfoLoading`, 
            value: false
        }));
        console.log(err)
        if (err.response && err.response.data && err.response.data.error === `"email" must be a valid email`) {
            toast.error(<><h4>{errorToastHeading}</h4><div>{errorToastBodyEmail}</div></>)
        } else {
            toast.error(<><h4>{errorToastHeading}</h4><div>{errorToastBodyGeneric}</div></>)
        }
    }
}

export default analysisState.reducer;