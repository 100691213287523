import { createSlice } from '@reduxjs/toolkit';

// Default state
const defaultState = {
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 63,
        longitude: 22.4376,
        zoom: 3.5
    },
    bordersSourceAndLayer: '',
    roadlinesSourceAndLayer: '',
    stationsSource: '',
    centerpointSource: ''
}

const initialState = defaultState;

// Initialize state and define reducers
const mapState = createSlice({    
    name: 'mapState',
    initialState,
    reducers: {
        _setViewport(state, action) {
            state.viewport = {...action.payload}
        },
        _setBordersSourceAndLayer(state, action) {
            state.bordersSourceAndLayer = action.payload
        },
        _setRoadlinesSourceAndLayer(state, action) {
            state.roadlinesSourceAndLayer = action.payload
        },
        _setRoadlinesLayerVisible(state, action) {
            state.roadlinesSourceAndLayer.layers[0].visible = action.payload
        },
        _setStationsSource(state, action) {
            state.stationsSource = action.payload;
        },
        _setStationsSourceVisible(state, action) {
            state.stationsSource.visible = action.payload
        },
        _setCenterpointSource(state, action) {
            state.centerpointSource = action.payload;
        },
        _setCenterpointSourceVisible(state, action) {
            state.centerpointSource.visible = action.payload
        },
    }
});

export const {
    _setViewport,
    _setBordersSourceAndLayer,
    _setRoadlinesSourceAndLayer,
    _setRoadlinesLayerVisible,
    _setStationsSource,
    _setStationsSourceVisible,
    _setCenterpointSource,
    _setCenterpointSourceVisible
} = mapState.actions;

export default mapState.reducer;