import React from 'react'
import styled from 'styled-components';


const NextCTAStyled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;

    border: transparent;
    outline: none;
    
    padding: 18px;

    background-color: var(--main-color-darkGrey);
    color: #FFFFFF;

    cursor: pointer;

    transition: .2s ease-in-out;

    &:disabled {
        cursor: default;
        
        background-color: var(--bg-color-2);

        span {
            color: var(--main-color-darkGrey);
            opacity: 0.2;
        }

        svg {
            opacity: 0.2;
            path {
                fill: var(--main-color-darkGrey);
            }
        }
    }

    span {
        margin-right: 18px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;

        text-transform: uppercase;
    }

    svg {
        display: block;
    }
`


export const NextCTA = ({
    labelText,
    onClick,
    disabled,
    style
}) => {

    return (
        <NextCTAStyled
            title={labelText}
            onClick={onClick}
            disabled={disabled}
            style={{...style}}
        >
            <span>
                {labelText} 
            </span>
            <svg width="51" height="12" viewBox="0 0 51 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    fillRule="evenodd" clipRule="evenodd" 
                    d="M44.685 0.238643L49.8703 5.42386C50.1884 5.74205 50.1884 6.25794 49.8703 6.57614L44.685 11.7614C44.3668 12.0795 43.851 12.0795 43.5328 11.7614C43.2146 11.4432 43.2146 10.9273 43.5328 10.6091L47.3271 6.81478H0V5.18522H47.3271L43.5328 1.39091C43.2146 1.07272 43.2146 0.556834 43.5328 0.238643C43.851 -0.0795478 44.3668 -0.0795478 44.685 0.238643Z" 
                    fill="white"
                />
            </svg>
        </NextCTAStyled>
    );
}

const BackButtonStyled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 44px;
    height: 40px;

    position: absolute;
    left: -44px;
    top: 12px;

    background-color: var(--bg-color-2);

    border: transparent;

    cursor: pointer;

    &:focus {
        outline: none;
    }

    @media (min-width:1280px) and (min-height: 750px)  {
        width: 56px;
        height: 48px;

        left: -52px;
        top: 46px;
    }
`

export const BackButton = ({
    onClick,
    style
}) => {

    return (
        <BackButtonStyled
            onClick={onClick}
            style={{...style}}
        >
            <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.42385 0.238643L0.238628 5.42386C-0.0795612 5.74205 -0.0795612 6.25794 0.238628 6.57614L5.42385 11.7614C5.74204 12.0795 6.25793 12.0795 6.57612 11.7614C6.89431 11.4432 6.89431 10.9273 6.57612 10.6091L2.78182 6.81478H19.2941C19.7441 6.81478 20.1089 6.44999 20.1089 6C20.1089 5.55001 19.7441 5.18522 19.2941 5.18522H2.78182L6.57612 1.39091C6.89431 1.07272 6.89431 0.556834 6.57612 0.238643C6.25793 -0.0795478 5.74204 -0.0795478 5.42385 0.238643Z" fill="#81919F"/>
            </svg>
        </BackButtonStyled>
    );
}