import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { _setViewport } from '../slices/mapSlice';

import ReactMapGL, { Layer, Marker, Source }  from 'react-map-gl';
import { LoadingSpinner } from './widgets/Loaders';

import useWindowSize from '../hooks/useWindowResize';

const MapSection = styled.div`
    position: relative;
    width: 100%;
    height: 40vh;
    order: -1;

    @media(min-width:1280px) and (min-height: 750px) {
        width: 50%;
        height: 100%;
        order: initial;
    }
`

const LoadingSpinnerDiv = styled.div`
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

`

const StationMarkerStyled = styled.div`
    transform: scale(0);
    
    display: flex;
    justify-content: center;
    align-items: center;

    width: 22px;
    height: 22px;

    border-radius: 50%;

    background: #FFFFFF;
    box-shadow: ${props => props.noShadow ? `initial` : `0px 4px 5px rgba(129, 145, 159, 0.5)`};

    animation: popUp .3s forwards;
    animation-delay: ${props => props.index * 0.1}s;

    div {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        ${
            props => ( 
                props.typeOfStation === 'national' 
                ? 
                css`background: var(--main-color-grey);` 
                : 
                css`background: var(--main-color-yellow);`
            )
        };
    }

    @keyframes popUp {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
`

export const StationMarker = ({ 
    typeOfStation,
    index,
    noShadow
}) => {

    return (
        <StationMarkerStyled
            typeOfStation={typeOfStation}
            index={index}
            noShadow={noShadow}
        >
            <div></div>
        </StationMarkerStyled>
    )
}

const metersToPixelsAtMaxZoom = (meters, latitude) => meters / 0.075 / Math.cos(latitude * Math.PI / 180)

const AnalysisMap = () => {
    // Global Redux state
    const dispatch = useDispatch();
    const { mapState, analysisState } = useSelector(state => state);

    // Country border
    const [countryCode, setCountryCode] = useState('');

    // Width
    const { width, height } = useWindowSize();

    useEffect(() => {
        if (analysisState.selectedAreaType === 'country' && analysisState.selectedCountryName) {
            setCountryCode(analysisState.countryCode)
        } else {
            setCountryCode('')
        }
    }, [analysisState]);


    useEffect(() => {
        dispatch(_setViewport({
            ...mapState.viewport,         
            width: '100%',
            height: '100%'
        }));
    }, [width, height]);

    return (
        <MapSection>
            <ReactMapGL 
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                mapStyle="mapbox://styles/leticiarezende/ckolb3fkx2oo718pf4jm3g66f"
                {...mapState.viewport}
                onViewportChange={nextViewport => dispatch(_setViewport(nextViewport))}
            >
                {
                    countryCode
                    ?
                    <Source
                        id="countriesBordersSource"
                        type="vector"
                        url="mapbox://mapbox.country-boundaries-v1"
                    >
                    <Layer
                        {...{
                            id: 'country-boundaries',
                            type: 'fill',
                            'source-layer': 'country_boundaries',
                            type: 'fill',
                            paint: {
                              'fill-color': '#AECACE',
                              'fill-opacity': 0.4,
                            },
                            filter: [
                                '!in', 
                                'iso_3166_1', 
                                countryCode
                            ]
                        }} 
                    />
                </Source>
                    :
                    null
                }
                {
                    mapState.bordersSourceAndLayer
                    ?
                    <Source 
                        id={'bordersSource'} 
                        type="geojson" 
                        data={mapState.bordersSourceAndLayer.data.data}
                    >
                        {
                            mapState.bordersSourceAndLayer.layers.filter(l => l.visible).map(layer => {
                                return (
                                    <Layer 
                                        key={layer.data.id}
                                        {...layer.data} 
                                    />

                                )
                            })

                        }
                    </Source>
                    :
                    null
                }
                {
                    mapState.roadlinesSourceAndLayer
                    ?
                    <Source 
                        id={'roadlinesSource'} 
                        type="geojson" 
                        data={mapState.roadlinesSourceAndLayer.data.data}
                    >
                        {
                            mapState.roadlinesSourceAndLayer.layers.filter(l => l.visible).map(layer => {
                                return (
                                    <Layer 
                                        key={layer.data.id}
                                        {...layer.data} 
                                    />

                                )
                            })

                        }
                    </Source>
                    :
                    null
                }
                {
                    mapState.stationsSource && mapState.stationsSource.visible 
                    ?
                    mapState.stationsSource.data.data.map((station, index) => (
                        <Marker
                            key={index}
                            latitude={station.geometry.coordinates[1]}
                            longitude={station.geometry.coordinates[0]}
                        >
                            <StationMarker
                                typeOfStation={station.properties.stationType}
                                index={index}
                            />
                        </Marker>
                    ))
                    :
                    null
                }
                {
                    mapState.centerpointSource && mapState.stationsSource.visible 
                    ?
                    <Source 
                        id={'centerpointSource'} 
                        type="geojson" 
                        data={mapState.centerpointSource.data.data}
                    >
                        <Layer
                            {...{
                                id: 'centerpointLayer',
                                type: 'circle',
                                source: 'centerpointSource',
                                paint: {
                                  'circle-color': '#EDC59E',
                                  'circle-opacity': .4,
                                  'circle-radius': {
                                      base: 2,
                                      stops: [
                                        [0, 0],
                                        [20, metersToPixelsAtMaxZoom(mapState.centerpointSource.data.data.properties.HubDist * 1000, mapState.centerpointSource.data.data.geometry.coordinates[1])]
                                      ]
                                  },
                                  'circle-stroke-width': 4,
                                  'circle-stroke-color': '#EDC59E',
                               },
                            }} 
                        />
                    </Source>
                    :
                    null
                }
            </ReactMapGL>
            {/* Loading spinner */}
            {
                analysisState.isMunicipalityRoadlinesLoading || analysisState.isMunicipalityStationsAndCenterPointsLoading
                ?
                <LoadingSpinnerDiv>
                    <LoadingSpinner />
                </LoadingSpinnerDiv>
                :
                null
            }
        </MapSection>
    );
}

export default AnalysisMap;