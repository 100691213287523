import { createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../api/axiosInstance';

import { fetchCountries, fetchCountrysMunicipalities } from '../api/endpoints/apiMain';

// Default state
const defaultState = {
    availableCountries: [],
    isAvailableCountriesLoading: false,
    availableCountriesError: null
}

const initialState = defaultState;

// Initialize state and define reducers
const globalState = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        _setAvailableCountires(state, action) {
            const countriesFromAPI = action.payload;
            state.availableCountries = [...countriesFromAPI]
        },
        _setIsAvailableCountriesLoading(state, action) {
            state.isAvailableCountriesLoading = action.payload;
        },
        _setAvailableCountriesError(state, action) {
            state.availableCountriesError = action.payload;
        }
    }
});

export const {
    _setAvailableCountires,
    _setIsAvailableCountriesLoading,
    _setAvailableCountriesError
} = globalState.actions;

export const fetchAndSetAvailableCountriesAndMunicipalities = () => async (dispatch, getState) => {
    const state = getState();

    try {
        dispatch(_setIsAvailableCountriesLoading(true));
        dispatch(_setAvailableCountriesError(null));

        const countriesData = await fetchCountries();

        for (let i = 0; i < countriesData.supported.length; i++) {
            // Set country municipalities
            countriesData.supported[i].municipalities = [];
            let municipalitiesData = await fetchCountrysMunicipalities(countriesData.supported[i].name);

            countriesData.supported[i].supported = true;
            countriesData.supported[i].municipalities = [...municipalitiesData];
        }

        dispatch(_setAvailableCountires([...countriesData.supported, ...countriesData.notSupported]))

        dispatch(_setIsAvailableCountriesLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(_setIsAvailableCountriesLoading(false));
        dispatch(_setAvailableCountriesError('Error'))
    }
}

export default globalState.reducer;