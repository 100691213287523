// React component for the analysis final step:
// Desc: Form with multiple inputs and a checkbox, Next CTA
// Visitor fills in the contact information, clicks "Next", loading modal appears, 
// the requests to generate PDF and add data to the database (send email?) (possibly one endpoint?) to the API are made

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import validator from 'validator';

import styled from 'styled-components';
import { BackButton, NextCTA } from '../components/widgets/Buttons';

import 'rc-slider/assets/index.css';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { AnalysisFullCalculateSendMailGeneratePDF, _setAnalysisStage, _setPercentagePerTreatmentActions } from '../slices/analysisSlice';
import { _setUserInfoField } from '../slices/userInfoSlice';

// Widgets
import { EmailInput, NameInput, PositionInput, PhoneInput } from '../components/widgets/InputWidgets';
import AgreeToTermsCheckbox from '../components/widgets/CheckboxWidgets';
import SelectOrganization from '../components/widgets/SelectWidgets';
import LoaderModal, { LoadingSpinner } from '../components/widgets/Loaders';

const PageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    overflow-y: auto;

    @media(min-width:1280px) and (min-height: 750px) {
        height: 100%;
    }
`

const SidePanelSection = styled.div`
    display: none;

    @media(min-width:1280px) and (min-height: 750px) {
        display: block;

        width: 20%;
        height: 100%;
        
        background-color: var(--bg-color-2);
    }
`

const ControlsSection = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;

    overflow-y: auto;

    .controlsSection__heading {
        color: var(--main-color-grey);

        h2 {
            font-weight: normal;
            font-size: 21px;
            line-height: 25px;

            margin-bottom: 16px;
        }

        div {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 8px;
        }
    }

    .controlsSection__form {

    }

    .nextCTA__wrapper {
        display: flex;
        width: 100%;

        margin-bottom: 24px;

        margin-top: 82px;

        button {
            width: 100%;
        }
    }

    @media(min-width:1280px) and (min-height: 750px) {
        width: 80%;
        height: 100%;

        padding-bottom: 10px;

        .controlsSection__heading {

            h2 {
                font-weight: bold;
                font-size: 40px;
                line-height: 48px;

                margin-bottom: 36px;
            }

            div {
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;

                margin-bottom: 36px;
            }
        }

        .controlsSection__form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .nextCTA__wrapper {
            margin-bottom: 0;

            margin-top: 24px;

            @media (min-height: 820px) {
                margin-top: 92px;
            }

            button {
                width: fit-content;
            }
        }
    }

`

const ControlsHeading = styled.div`
    position: relative;
    color: var(--main-color-blue);

    margin-bottom: 16px;

    @media(min-width:1280px) and (min-height: 750px) {
        @media (min-height: 820px) {
            margin-bottom: 108px;
        }
    }

    .controlsHeading__currentStepNo {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: 600;
            font-size: 160px;
            line-height: 192px;
        } 
    }

    .controlsHeading__stepsTotal {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        } 

    }
`


const AnalysisStepSixPage = () => {
    const { t, i18n, ready } = useTranslation('analysis-step-6');

    const dispatch = useDispatch();
    const { globalState, mapState, analysisState, userInfo } = useSelector(state => state);

    let history = useHistory();

    const handleNextStep = () => {
        dispatch(AnalysisFullCalculateSendMailGeneratePDF(
            () => history.push('/analysis-results-full'),
            i18n.language,
            t(`toasts.errorToastHeading`),
            t(`toasts.errorToastBodyGeneric`),
            t(`toasts.errorToastBodyEmail`)
        ));
    }

    const handleGoBack = () => {
        dispatch(_setAnalysisStage('input_percentage_of_actions'));

        history.goBack();
    }

    // Simple validation
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (analysisState.analysisStage !== 'form_all_steps' && analysisState.analysisStage !== 'input_percentage_of_actions') {
            history.push('/')
        }
    }, []);

    useEffect(() => {
        if (!userInfo.name || !userInfo.email || (userInfo.email && !validator.isEmail(userInfo.email)) || !userInfo.agreedToTerms) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [userInfo]);

    return (
        <>
        <PageContainer>
            <ControlsSection>
                <ControlsHeading>
                    <span
                        className="controlsHeading__currentStepNo"
                    >
                        06
                    </span>
                    <span
                        className="controlsHeading__stepsTotal"
                    >
                        /6
                    </span>
                    <BackButton
                        onClick={() => handleGoBack()}
                    />
                </ControlsHeading>
                {
                ready 
                ?
                <>
                <div
                    className="controlsSection__heading"
                >
                    <h2>
                        {t(`heading`)}
                    </h2>
                    <div>
                        {t(`subheading`)}
                    </div>
                </div>
                <form
                    className="controlsSection__form"
                >
                    <NameInput
                        value={userInfo.name}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `name`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <EmailInput
                        value={userInfo.email}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `email`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <PhoneInput 
                        value={userInfo.phoneNumber}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `phoneNumber`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <SelectOrganization 
                        value={userInfo.organizationType}
                        onChange={(v) => {
                            dispatch(_setUserInfoField({
                                field: `organizationType`, 
                                value: v
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <PositionInput 
                        value={userInfo.position}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `position`, 
                                value: e.target.value
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                    <AgreeToTermsCheckbox 
                        checked={userInfo.agreedToTerms}
                        onChange={(e) => {
                            dispatch(_setUserInfoField({
                                field: `agreedToTerms`, 
                                value: e.target.checked
                            }))
                        }}
                        disabled={analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading}
                    />
                </form>
                <div
                    className="nextCTA__wrapper"
                >
                    <NextCTA
                        labelText={t(`nextCTA`)}
                        onClick={() => handleNextStep()}
                        disabled={!isValid}
                    />
                </div>
                </>
                :
                <LoadingSpinner />
                }
            </ControlsSection>
            <SidePanelSection>
            </SidePanelSection>
        </PageContainer>
        {
            analysisState.isCalculationsLoading || analysisState.isPDFLinkLoading
            ?
            <LoaderModal>
                <div
                    className="analysisStep6__loadingModalContent"
                >
                    <LoadingSpinner />
                    <h2>
                        {t(`loadingModalHeading`)}
                    </h2>
                    <div>
                        {t(`loadingModalBody`)}
                    </div>
                </div>
            </LoaderModal>
            :
            null
        }
        </>
    );
}

export default AnalysisStepSixPage;