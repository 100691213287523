// React component for the analysis third step:
// Desc: Dynamic content, reactive map
// Stations and center points are displayed; the IsCoverageGood box is calculated and displayed

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { _setStationsSource, _setCenterpointSource, _setRoadlinesLayerVisible, _setStationsSourceVisible, _setCenterpointSourceVisible } from '../slices/mapSlice';
import { _setAnalysisStage } from '../slices/analysisSlice';
import { BackButton, NextCTA } from '../components/widgets/Buttons';
import { LoadingSpinner } from '../components/widgets/Loaders';

const ControlsSection = styled.div`
    position: relative;

    width: 100%;
    height: 60vh;

    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;

    overflow-y: auto;

    .controlsSection__numStations {
        color: var(--main-color-grey);

        font-weight: normal;
        font-size: 21px;
        line-height: 25px;

        margin-bottom: 16px;
    }

    .controlsSection__averageDistance {
        color: var(--main-color-grey);

        font-size: 12px;
        line-height: 14px;

        margin-bottom: 1rem;
    }

    .nextCTA__wrapper {
        position: fixed;

        bottom: 20px;

        display: flex;
        width: 100%;

        margin-bottom: 24px;

        margin-top: 82px;

        button {
            width: calc(100% - 100px);
        }

        @media(min-width:1280px) and (min-height: 750px) {
            position: static;

            margin-bottom: 0;
            margin-top: 72px;

            button {
                width: fit-content;
            }
        }
    }

    @media(min-width:1280px) and (min-height: 750px) {
        width: 50%;
        height: 100%;

        padding-bottom: 10px;

        .controlsSection__numStations {
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;

            margin-bottom: 32px;
        }

        .controlsSection__averageDistance {
            font-size: 16px;
            line-height: 19px;

            margin-bottom: 22px;
        }
    }
`

const ControlsSubheading = styled.div`
    font-size: 14px;
    line-height: 17px;
    span {
        color: var(--main-color-darkGrey);
    }

    @media(min-width:1280px) and (min-height: 750px) {
        font-size: 20px;
        line-height: 24px;
    } 
`

const ControlsHeading = styled.div`
    position: relative;
    color: var(--main-color-blue);

    margin-bottom: 16px;

    @media(min-width:1280px) and (min-height: 750px) {
        @media (min-height: 820px) {
            margin-bottom: 108px;
        }
    }

    .controlsHeading__currentStepNo {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: 600;
            font-size: 160px;
            line-height: 192px;
        } 
    }

    .controlsHeading__stepsTotal {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        } 

    }
`

const GoodCoverageBox = styled.div`
    border-left: 3px var(--main-color-green) solid;
    background: var(--bg-color-2);

    padding: 20px;

    h4 {
        color: var(--main-color-green);

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-bottom: 10px;
        margin-top: 0;
    }

    div {
        color: var(--main-color-darkGrey);
        text-align: justify;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        a {
            text-decoration: none;
            color: var(--main-color-blue)
        }
    }

    @media (min-width:1280px) and (min-height: 750px) {
        border-left: 5px var(--main-color-green) solid;
        h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }

        div {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
        }
    }
`
const LackCoverageBox = styled.div`
    border-left: 3px var(--main-color-yellow) solid;
    background: var(--main-color-grey);

    padding: 20px;

    h4 {
        color: var(--main-color-yellow);

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        margin-bottom: 10px;
        margin-top: 0;
    }

    div {
        color: #FFFFFF;
        text-align: justify;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        a {
            text-decoration: none;
            color: var(--main-color-blue)
        }
    }

    @media (min-width:1280px) and (min-height: 750px) {
        border-left: 5px var(--main-color-yellow) solid;
        h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }

        div {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
        }
    }

`

const MapLegendSection = styled.div`
    h5:first-child {
        color: var(--main-color-grey);

        font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        margin-bottom: 10px;
    }

    h5:last-child {
        color: var(--main-color-darkGrey);

        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
    }

    .mapLegend__flexbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        padding: 9px;
        padding-top: 20px;

        margin-bottom: 9px;

        background: var(--bg-color-2);

        @media(min-width:1280px) and (min-height: 750px) {
            justify-content: space-around;
        }
    }

    .mapLegend__flexbox__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-left: 11px;
        margin-right: 11px;
        margin-bottom: 6px;

        div:first-child {
            margin-right: 22px;
        }

        div:last-child {
            color: var(--main-color-grey);

            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
        }
    }
`

const NationalStationsMarker = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;

    background: var(--main-color-grey);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 5px rgba(129, 145, 159, 0.5);
`
const KlimatorStationsMarker = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;

    background: var(--main-color-yellow);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 5px rgba(129, 145, 159, 0.5);
`
const BalancePointMarker = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;

    background: rgba(237, 197, 158, 0.25);
    border: 2px solid var(--main-color-yellow);
    box-shadow: 0px 4px 5px rgba(129, 145, 159, 0.5);
`

const AnalysisStepThreePage = () => {
    const { t, i18n, ready } = useTranslation('analysis-step-3');

    const dispatch = useDispatch();
    const { globalState, mapState, analysisState } = useSelector(state => state);

    let history = useHistory();


    const handleNextStep = () => {
        dispatch(_setAnalysisStage('input_num_of_actions'));
        history.push('/analysis-step-4');
    }

    const handleGoBack = () => {
        dispatch(_setAnalysisStage('show_roads_data'));

        if (mapState.roadlinesSourceAndLayer) {
            dispatch(_setRoadlinesLayerVisible(true));
        }
        dispatch(_setStationsSourceVisible(false));
        dispatch(_setCenterpointSourceVisible(false));

        history.goBack();
    }

    // Wrong page check
    useEffect(() => {
        if (analysisState.analysisStage === 'input_num_of_actions') {
            dispatch(_setAnalysisStage('show_stations_data'));
        } else if (analysisState.analysisStage === 'show_roads_data' && mapState.centerpointSource) {
            dispatch(_setAnalysisStage('show_stations_data'));
            dispatch(_setRoadlinesLayerVisible(false));
            dispatch(_setStationsSourceVisible(true));
            dispatch(_setCenterpointSourceVisible(true));
        } else if (analysisState.analysisStage !== 'show_stations_data' && !(analysisState.municipalityStations.length > 0)) {
            dispatch(_setAnalysisStage('prestart'));
            history.push('/');
        } 
    }, []);

    // Fetch stations and centerpoint
    useEffect(() => {
        if (analysisState.municipalityStations.length > 0 && !mapState.stationsSource && !mapState.centerpointSource) {
            // Stations source
            const stationsSource = {
                visible: true,
                data: {
                    id: 'stations',
                    type: 'geojson',
                    data: analysisState.municipalityStations
                },
                layers: []
            }

            // Centerpoint source
            const centerpointSource = {
                visible: true,
                data: {
                    id: 'centerpoint',
                    type: 'geojson',
                    data: analysisState.municipalityCenterPoint
                },
                layers: []
            }

            dispatch(_setStationsSource(stationsSource));
            dispatch(_setCenterpointSource(centerpointSource));
        }

    }, [analysisState.municipalityStations]);


    return (
        <ControlsSection>
            <ControlsHeading>
                <span
                    className="controlsHeading__currentStepNo"
                >
                    03
                </span>
                <span
                    className="controlsHeading__stepsTotal"
                >
                    /6
                </span>
                <BackButton 
                    onClick={() => handleGoBack()}
                />
            </ControlsHeading>
            {
            ready
            ?
            <>
            <ControlsSubheading>
                <span>{t(`subheading`)}</span><strong>{analysisState.selectedMunicipalityName}</strong>
            </ControlsSubheading>
            <div
                className="controlsSection__numStations"
                dangerouslySetInnerHTML={{
                    __html: analysisState.municipalityBoundaries[0] && analysisState.municipalityBoundaries[0].properties.NSTATIONS === 1
                    ?
                    t(`heading_singular`)
                    :                    
                    t(
                        `heading`, 
                        {
                            numStations: `${analysisState.municipalityBoundaries[0] && analysisState.municipalityBoundaries[0].properties.NSTATIONS}`
                        }
                    )
                }}
            />
            <div
                className="controlsSection__averageDistance"
                dangerouslySetInnerHTML={{
                    __html: t(
                        `mainRoadAverageDistance`, 
                        {
                            distance: `${analysisState.averageDistanceToStation && Math.round(analysisState.averageDistanceToStation) }`
                        }
                        )
                }}
            />
            {
                analysisState.coverageGood
                ?
                <GoodCoverageBox>
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: t(`goodCoverageBox.heading`)
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t(`goodCoverageBox.body`, { url: `https://www.en.klimator.dk/sensors` })
                        }}
                    />
                </GoodCoverageBox>
                :
                <LackCoverageBox>
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: t(`lackCoverageBox.heading`)
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t(`lackCoverageBox.body`, { url: `https://www.en.klimator.dk/sensors` })
                        }}
                    />
                </LackCoverageBox>
            }
            <div
                className="nextCTA__wrapper"
            >
                <NextCTA
                    labelText={t(`nextCTA`)}
                    onClick={() => handleNextStep()}
                    disabled={false}
                />
            </div>
            <MapLegendSection>
                <h5>
                    {t(`mapLegend.title`)}
                </h5>
                <div
                    className="mapLegend__flexbox"
                >
                    <div
                        className="mapLegend__flexbox__item"
                    >
                        <NationalStationsMarker/>
                        <div>
                            {t(`mapLegend.nationalStations`)}
                        </div>
                    </div>
                    <div
                        className="mapLegend__flexbox__item"
                    >
                        <KlimatorStationsMarker/>
                        <div>
                            {t(`mapLegend.klimatorStations`)}
                        </div>
                    </div>
                    <div
                        className="mapLegend__flexbox__item"
                    >
                        <BalancePointMarker/>
                        <div>
                            {t(`mapLegend.balancingPoint`)}
                        </div>
                    </div>
                </div>
                <h5>
                    {t(`mapLegend.footerNote`)}
                </h5>
            </MapLegendSection>
            </>
            :
            <LoadingSpinner />
            }
        </ControlsSection>
    );
}

export default AnalysisStepThreePage;