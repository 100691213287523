import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Route, Switch } from 'react-router';
import { BrowserRouter, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetAvailableCountriesAndMunicipalities } from './slices/globalStateSlice';


// Toasts
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Pages
import LandingPage from './pages/landing-page';
import AnalysisMapWrapper from './pages/analysis-stepsMap-wrapper';
import AnalysisStepFourPage from './pages/analysis-step-4';
import AnalysisStepFivePage from './pages/analysis-step-5';
import AnalysisStepSixPage from './pages/analysis-step-6';
import AnalysisFinalStepFull from './pages/analysis-results-full';
import AnalysisResultsLackData from './pages/analysis-results-lack-data';
import DynamicPDFPage from './pages/dynamic-pdf-page';
import AnalysisResultsNoData from './pages/analysis-results-no-country';

const ApplicationContainer = styled.div`
  height: 100vh;
  width: 100vw;

  .analysisStep6__loadingModalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--main-color-grey);

    @media (min-width: 1440px) {
      h2 {
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
      }
      div {
        font-weight: normal;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  .analysisLackData__loadingModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .analysisLackData__finishedModalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--main-color-grey);

    padding-left: 2rem;

    @media (min-width: 1440px) {
      h2 {
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
      }
      div {
        font-weight: normal;
        font-size: 24px;
        line-height: 34px;
      }
    }

    .indicator__background {
      position: absolute;
      width: 130px;
      height: 130px;

      border-radius: 50%;
      border: 12px #AECACE solid;
    }

    .indicator__borders {
      display: inline-block;
      position: relative;
      width: 130px;
      height: 130px;
    }
    .indicator__borders div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 130px;
      height: 130px;
      border: 12px solid #81919F;
      border-radius: 50%;

      transform: rotate(45deg);
      animation: fadeIn 5s cubic-bezier(0.5, 0, 0.5, 1) forwards;
      border-color: #81919F transparent transparent transparent;

      @keyframes fadeIn {
        0% {
          border-color: transparent transparent transparent transparent;
        }
        25% {
          border-color: #81919F transparent transparent transparent;
        }
        50% {
          border-color: #81919F #81919F transparent transparent;
        }
        75% {
          border-color: #81919F #81919F #81919F transparent;
        }
        100% {
          border-color: #81919F #81919F #81919F #81919F;
        }
      }
    }
  }

  .errorToast {
    background-color: #B31E50;
    color: #FFFFFF;

    h4 {
      font-weight: bold;

      margin-top: 0;
      margin-bottom: 0;
    }

    display: flex;
    border-radius: 4px;
    cursor: pointer;

    min-height: 3rem;
    padding: .3rem;
  }
`

function App() {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const { globalState } = useSelector(state => state);

  // Toasts configurations
  const contextClass = {
    error: "errorToast"
  };

  useEffect(() => {
    dispatch(fetchAndSetAvailableCountriesAndMunicipalities())
  }, []);

  // TODO: Add loading spinner
  if (!ready) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter
      basename={(i18n.language && i18n.language.length === 2) ? i18n.language : `en`}
    >
      <ApplicationContainer>
        <ToastContainer 
          toastClassName={({ type }) => contextClass[type || "default"]}
        />
        <Switch>
          <Route
            path="/dynamic-pdf-page"
            >
            <DynamicPDFPage />
          </Route>
          <Route
            path="/analysis-results-no-country"
          >
            <AnalysisResultsNoData />
          </Route>
          <Route
            path="/analysis-results-lack-data"
          >
            <AnalysisResultsLackData />
          </Route>
          <Route
            path="/analysis-results-full"
          >
            <AnalysisFinalStepFull />
          </Route>
          <Route
            path="/analysis-step-6"
          >
            <AnalysisStepSixPage />
          </Route>
          <Route
            path="/analysis-step-5"
          >
            <AnalysisStepFivePage />
          </Route>
          <Route
            path="/analysis-step-4"
          >
            <AnalysisStepFourPage />
          </Route>
          <Route
            path={["/analysis-step-1", "/analysis-step-2", "/analysis-step-3"]}
          >
            <AnalysisMapWrapper />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
        </Switch>
      </ApplicationContainer>
    </BrowserRouter>
  );
}

export default App;
