// React component for the analysis first step
// Desc: Input form, reactive map
// Choose country/municipality; camera zooms in, boundaries painted

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetCountryBoundaries, fetchAndSetMunicipalityBordersAndRoadsData, resetCountryAndMunicipality, _setAnalysisStage, _setMunicipalityCenterpoint, _setMunicipalityRoadlines, _setMunicipalityStations, _setNumberOfTreatmentActions, _setPercentagePerTreatmentActions, _setRoadNetworkData } from '../slices/analysisSlice';
import { _setViewport, _addSourceWithLayer, _setSourcesLayerVisible, _setRoadlinesSourceAndLayer, _setBordersSourceAndLayer, _setRoadlinesLayerVisible, _setStationsSource, _setCenterpointSource } from '../slices/mapSlice';


import lookingGlass from '../assets/looking-glass.svg';



// Input
import Autosuggest from 'react-autosuggest';
import isMobile from 'ismobilejs';

import { BackButton, NextCTA } from '../components/widgets/Buttons';

// Map
import { LinearInterpolator, WebMercatorViewport }  from 'react-map-gl';
import bbox from '@turf/bbox';
import difference from '@turf/difference';
import simplified from '@turf/simplify';

import useWindowSize from '../hooks/useWindowResize';
import { findCountryBox } from '../components/countryBoundingBox';
import { world } from '../components/worldPolygon';
import { _resetUserInfoFields } from '../slices/userInfoSlice';
import { LoadingSpinner } from '../components/widgets/Loaders';

const ControlsSection = styled.div`
    position: relative;

    width: 100%;
    height: 60vh;

    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;

    .controlsSection__question {
        color: var(--main-color-grey);

        font-weight: normal;
        font-size: 21px;
        line-height: 25px;

        margin-bottom: 16px;
    }

    .nextCTA__wrapper {
        display: flex;
        width: 100%;

        margin-bottom: 24px;

        margin-top: 82px;

        button {
            width: 100%;
        }

        @media(min-width:1280px) and (min-height: 750px) {
            margin-bottom: 0;

            @media (min-height: 820px) {
                margin-top: 182px;
            }

            button {
                width: fit-content;
            }
        }
    }

    @media(min-width:1280px) and (min-height: 750px) {
        width: 50%;

        .controlsSection__question {
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;

            margin-bottom: 32px;
        }
    }

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__container::after {
        content: "";

        position: absolute;
        right: 5px;
        top: calc(50% - 26px);

        height: 53px;
        width: 53px;

        background: url(${lookingGlass}) no-repeat;
    }

    .react-autosuggest__input {
        width: 100%;
        min-height: 73px;
        height: fit-content;
        padding: 10px 6px;


        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;


        border: 3px solid rgba(129, 145, 159, 0.3);


        @media (min-width:1280px) and (min-height: 750px) {
            font-size: 18px;
            line-height: 22px;

            padding: 10px 30px;
        }
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        bottom: 73px;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: inherit;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;

        overflow-y: auto;

        max-height: 30vh;

        z-index: 20;

        @media(min-width:1280px) and (min-height: 750px) {
            bottom: initial;
            top: 73px;
        }
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }

    .react-autosuggest__suggestion-match {
        color: red;
        font-weight: bold;
    }
`
const ControlsHeading = styled.div`
    position: relative;
    color: var(--main-color-blue);

    margin-bottom: 16px;

    @media(min-width:1280px) and (min-height: 750px) {

        @media (min-height: 820px) {
            margin-bottom: 108px;
        }
    }

    .controlsHeading__currentStepNo {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: 600;
            font-size: 160px;
            line-height: 192px;
        } 
    }

    .controlsHeading__stepsTotal {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        @media(min-width:1280px) and (min-height: 750px) {
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        } 

    }

`


const renderSuggestion = (suggestion, i) => (
    suggestion.type === 'municipality'
    ?
    <span>
        {suggestion.name}
    </span>
    :
    <span style={{position: 'relative', paddingLeft: '.5rem'}}>
        {
            suggestion.icon
            ?
            <img 
                style={{
                    height: '12px',
                    position: 'absolute',
                    left: '-14px',
                    top: '6px'
                }} 
                src={suggestion.icon} 
            />
            :
            <span
                style={{
                    height: '12px',
                    position: 'absolute',
                    left: '-14px',
                    top: '0'
                }} 
                dangerouslySetInnerHTML={{
                    __html: suggestion.iconEmoji
                }}
            />
        }
        {suggestion.displayName}
    </span>
  );

const AnalysisStepOnePage = () => {
    const { t, i18n, ready } = useTranslation('analysis-step-1');

    const dispatch = useDispatch();
    const { globalState, analysisState, mapState } = useSelector(state => state);

    let history = useHistory();

    // Local state
    const { width, height } = useWindowSize()

    const [municipalities, setMunicipalities] = useState([]);
    
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [selectedArea, setSelectedArea] = useState(null);

    // Auto suggestions
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
      
        if (inputLength === 0) {
            return []
        } else {
            let workingArray = municipalities.filter(m => {
                if (
                    m.name.toLowerCase().slice(0, inputLength) === inputValue || 
                    m.displayName && m.displayName.toLowerCase().slice(0, inputLength) === inputValue || 
                    (m.country && m.country.toLowerCase().slice(0, inputLength) === inputValue) ||
                    (m.countryDisplayName && m.countryDisplayName.toLowerCase().slice(0, inputLength) === inputValue)
                ) return true
            });

            return workingArray;
        }
    };

    const getSuggestionValue = suggestion => suggestion.displayName ? suggestion.displayName : suggestion.name;

    const onInputChange = (event, { newValue }) => {
        setInputValue(newValue);
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(suggestions => [...getSuggestions(value)]);
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions(suggestions => []);
    };

    const onSuggestionSelected = (event, value) => {
        setSelectedArea(value.suggestion)
    }

    const inputProps = {
        placeholder: t(`inputPlaceholder`),
        value: inputValue,
        onChange: onInputChange
    }

    // Handle move to the next step
    const handleConfirmAndNextStep = () => {

        if (analysisState.selectedAreaType === 'municipality') {
            dispatch(_setAnalysisStage('show_roads_data'));
            history.push('/analysis-step-2');
        } else if (analysisState.selectedAreaType === 'country' && analysisState.countrySupported) {
            dispatch(_setAnalysisStage('form_lack_data'));
            history.push('/analysis-results-lack-data');
        } else {
            dispatch(_setAnalysisStage('form_no_data'));
            history.push('/analysis-results-no-country');
        }
    }

    // Handle back button behavior
    const handleResetSelectedArea = () => {
        dispatch(_setViewport({
            width: '100%',
            height: '100%',
            latitude: 63,
            longitude: 22.4376,
            zoom: width > 640 ? 3.5 : 0.6,
            transitionInterpolator: new LinearInterpolator(),
            transitionDuration: 1000
        }));
        setSelectedArea('');
        setInputValue('');
    }

    // Set map zoom on the first mount
    useEffect(() => {
        if (analysisState.analysisStage === 'show_roads_data' || analysisState.analysisStage === 'form_lack_data') {
            dispatch(_setAnalysisStage('choose_location'));

            dispatch(_setMunicipalityRoadlines([]));
    
            dispatch(_setRoadlinesSourceAndLayer(''));
    
            dispatch(_setMunicipalityStations([]));
            dispatch(_setMunicipalityCenterpoint({}));
    
            dispatch(_setStationsSource(''));
    
            dispatch(_setCenterpointSource(''));
    
            dispatch(_setNumberOfTreatmentActions(0));
    
            dispatch(_setPercentagePerTreatmentActions(0));

            dispatch(_resetUserInfoFields());
        } else {
            if (width < 640) {
                dispatch(_setViewport({
                    ...mapState.viewport,
                    zoom: 0,
                }));
            }
        }
    }, []);



    // Set up the data for the auto suggestions
    useEffect(() => {
        let municipalitiesWorking = [];

        let lang = (i18n.language && i18n.language.length === 2) ? i18n.language : `en`

        globalState.availableCountries && globalState.availableCountries.length > 0 && globalState.availableCountries.forEach((country, index) => {
            let countryItem = {
                type: 'country',
                name: country.name,
                countryCode: country.countryCode,
                displayName: country.countryNames[lang],
                icon: country.githubFlag,
                iconEmoji: country.emojiFlag,
                supported: country.supported
            }

            municipalitiesWorking.push(countryItem);

            let countryName = country.name
    
            country.supported && country.municipalities && country.municipalities.forEach(m => {
                if (m.name) {
                    let municipalityItem = {
                        type: 'municipality',
                        country: countryName,
                        countryDisplayName: country.countryNames[lang],
                        name: m.name,
                        id: m.id ? m.id : m.kid
                    }

                    municipalitiesWorking.push(municipalityItem)
                }
            });
        })

        setMunicipalities(municipalities => [...municipalitiesWorking]);
    }, [globalState.availableCountries, ready, i18n.language]);

    // Fetch area data once the municipality/country was selected
    useEffect(() => {
        async function fetchAreaData(areaObject) {
            try {
                if (areaObject.type === 'municipality') {
                    dispatch(fetchAndSetMunicipalityBordersAndRoadsData(areaObject.country, areaObject.id));
                } else {
                    dispatch(fetchAndSetCountryBoundaries(areaObject.name, areaObject.countryCode, areaObject.supported, areaObject.displayName))
                }
            } catch (err) {
                console.log(err)
            }
        }

        if (selectedArea) {
            fetchAreaData(selectedArea)
        } else {
            dispatch(resetCountryAndMunicipality())
        }

    }, [selectedArea]);

    // Zoom the map and highlight borders on recieving the data
    useEffect(() => {
        try {
            if (analysisState.municipalityBoundaries.length > 0) {
                // Zoom based on borders' bounding box
                const [minLng, minLat, maxLng, maxLat] = bbox(analysisState.municipalityBoundaries[0]);

                const vp = new WebMercatorViewport({
                    ...mapState.viewport,
                    width: (width < 1280 && height < 750) ? width : Math.round(width / 2),
                    height: width < 1280 ? Math.round(height / 2) : height
                });

                const {longitude, latitude, zoom} = vp.fitBounds(
                    [
                    [minLng, minLat],
                    [maxLng, maxLat]
                    ],
                    {
                    padding: 40
                    }
                );
                
                // Exclude geojson borders data with Turf mask and add new source with the layer
                let jsonData = {...analysisState.municipalityBoundaries[0]};
                let simplifiedJSON = simplified(jsonData, {tolerance: 0.0001, highQuality: false});

                let excludedPolygon = difference(world, simplifiedJSON)

                const bordersSourceExcluded = {
                    visible: true,
                    data: {
                        id: 'borders',
                        type: 'geojson',
                        data: excludedPolygon
                    },
                    layers: []
                }

                const bordersLayerExcluded = {
                    visible: true,
                    data: {
                        id: `borders-highlighted`,
                        type: 'fill',
                        paint: {
                            'fill-outline-color': '#AECACE',
                            'fill-color': '#AECACE',
                            'fill-opacity': .55
                        }
                    }
                }

                bordersSourceExcluded.layers.push(bordersLayerExcluded);

                // Update state
                dispatch(_setViewport({
                    ...mapState.viewport,
                    longitude,
                    latitude,
                    zoom,
                    transitionInterpolator: new LinearInterpolator(),
                    transitionDuration: 1000
                }));

                
                dispatch(_setBordersSourceAndLayer(bordersSourceExcluded));
            } else {
                dispatch(_setBordersSourceAndLayer(''));
            }

        } catch (err) {
            console.log(err)
        }

    }, [analysisState.municipalityBoundaries]);

    useEffect(() => {
        try {
            if (analysisState.selectedCountryName && analysisState.selectedAreaType === 'country') {
                let [minLng, minLat, maxLng, maxLat] = findCountryBox(analysisState.countryCode)
    
                const vp = new WebMercatorViewport({
                    ...mapState.viewport,
                    width: (width < 1280 && height < 750) ? width : Math.round(width / 2),
                    height: width < 1280 ? Math.round(height / 2) : height
                });
    
                const {longitude, latitude, zoom} = vp.fitBounds(
                    [
                      [minLng, minLat],
                      [maxLng, maxLat]
                    ],
                    {
                      padding: 40
                    }
                );
    
                // Update state
                dispatch(_setViewport({
                    ...mapState.viewport,
                    longitude,
                    latitude,
                    zoom,
                    transitionInterpolator: new LinearInterpolator(),
                    transitionDuration: 1000
                }));
            }    
        } catch (err) {
            console.log(err)
        }

    }, [analysisState.selectedCountryName, analysisState.selectedAreaType, analysisState.countryCode])



    return (
        <ControlsSection>
            <ControlsHeading>
                <span
                    className="controlsHeading__currentStepNo"
                >
                    01
                </span>
                <span
                    className="controlsHeading__stepsTotal"
                >
                    /6
                </span>
                {
                    selectedArea
                    ?
                    <BackButton
                        onClick={() => handleResetSelectedArea()}
                    />
                    :
                    null
                }
            </ControlsHeading>
            {
            ready
            ?
            <>
            <div
                className="controlsSection__question"
                dangerouslySetInnerHTML={{
                    __html: t(`chooseMunicipalityLabel`)
                }}
            />
            <Autosuggest 
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
                alwaysRenderSuggestions={!isMobile(window && window.navigator)}
            />
            <div
                className="nextCTA__wrapper"
            >
                <NextCTA
                    labelText={t(`confirm`)}
                    onClick={() => handleConfirmAndNextStep()}
                    disabled={!selectedArea}
                />
            </div>
            </>
            :
            <LoadingSpinner/>
            }
        </ControlsSection>
    );
}

export default AnalysisStepOnePage;