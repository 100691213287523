import { axiosInstance as axios } from '../axiosInstance';
import { BASE_URL } from '../url';

/**
 * Sends email with the filled-in form data
 * @async
 * @function sendMail
 * @param {Object} userInfo - Object containing information about the user
 * @returns 200 if email was sent successfully
 * ```
 * {
 *   reportUrl: `/pdf/reports/generated-pdf-id-123456/report.pdf`
 * }
 * ```
*/
export async function sendMail(
    userInfo
) {

    const response = await axios.post(
        `${BASE_URL}/mailman`,
        userInfo
    );

    return response;
}