import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';

import styled from 'styled-components';

import AnalysisMap from '../components/AnalysisMap';
import AnalysisStepOnePage from './analysis-step-1';
import AnalysisStepTwoPage from './analysis-step-2';
import AnalysisStepThreePage from './analysis-step-3';


const PageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    overflow-y: auto;

    @media(min-width:1280px) and (min-height: 750px) {
        height: 100%;
    }
`

const AnalysisMapWrapper = () => {


    return (
        <PageContainer>
            <Switch>
            <Route path="/analysis-step-3">
                <AnalysisStepThreePage />
            </Route>
            <Route path="/analysis-step-2">
                <AnalysisStepTwoPage />
            </Route>
            <Route path="/analysis-step-1">
                <AnalysisStepOnePage />
            </Route>
            </Switch>
            <AnalysisMap />
        </PageContainer>
    );
}

export default AnalysisMapWrapper;