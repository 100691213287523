import { combineReducers } from '@reduxjs/toolkit';

import globalStateReducer from '../slices/globalStateSlice';
import analysisStateReducer from '../slices/analysisSlice';
import mapStateReducer from '../slices/mapSlice';
import userInfoStateReducer from '../slices/userInfoSlice';

const rootReducer = combineReducers({
    globalState: globalStateReducer,
    userInfo: userInfoStateReducer,
    analysisState: analysisStateReducer,
    mapState: mapStateReducer
});

export default rootReducer;